import React, { useState } from 'react'
import constant from '../../../../constant';
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import Pagination from '../../../common/Pagination';
import { ToastContainer, toast } from 'react-toastify';
import styles from './crmuser.module.css'

const Listuser = (props) => {
    const { list, count, setCurrentPage, currentPage } = props;


    return (
        <React.Fragment>

            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <ToastContainer />
                        <div className="row">
                            <div className="col-lg-12">
                                <div className={`${styles['crm-card']} my-2`} id="tasksList">
                                    <div className="card-header border-0 customListUserHeader">
                                        <div class="row">
                                            <div class="col-md-4 d-flex align-items-center">
                                                <h5 className={`${styles['crm-title']} mb-0`}>CRM USERS</h5>
                                            </div>
                                            <div class={`${styles['custom-card']} col-md-8`}>
                                                <div className="search-box customUserSearchBox me-2">
                                                    <input
                                                        type="text"
                                                        className={`${styles['input-crm']} form-control search bg-light border-light`}
                                                        placeholder="Search for user..."
                                                        value={props.searchUser}
                                                        onChange={(e) => {
                                                            props.setSearchUser(e.target.value);
                                                            props.setCurrentPage(1);
                                                        }}

                                                    />
                                                    <i className="ri-search-line search-icon" />
                                                </div>
                                                <div>
                                                    <button onClick={() => {
                                                        debugger
                                                        props.history.push(constant.companyUserComponent.addUser.url)
                                                    }}
                                                        className={`${styles['btn-add']} btn addUserPageButton`}
                                                    >
                                                        <i className="ri-add-line align-bottom me-1" />Add New User
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <form>
                                            <div className="row p-0">
                                                {/* <div className="col-xxl-5 col-sm-12">
                                                    
                                                </div> */}
                                                {/*end col*/}
                                                {/* <div className="col-xxl-3 col-sm-4">
                                                    <input
                                                        type="text"
                                                        className="form-control bg-light border-light"
                                                        id="demo-datepicker"
                                                        data-provider="flatpickr"
                                                        data-date-format="d M, Y"
                                                        data-range-date="true"
                                                        placeholder="Select date range"
                                                    />
                                                </div>
                                                <div className="col-xxl-3 col-sm-4">
                                                    <div className="input-light">
                                                        <select
                                                            className="form-control"
                                                            data-choices=""
                                                            data-choices-search-false=""
                                                            name="choices-single-default"
                                                            id="idStatus"
                                                        >
                                                            <option value="">Status</option>
                                                            <option value="all" selected="">
                                                                All
                                                            </option>
                                                            <option value="New">New</option>
                                                            <option value="Pending">Pending</option>
                                                            <option value="Inprogress">Inprogress</option>
                                                            <option value="Completed">Completed</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-1 col-sm-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary w-100"
                                                        onclick="SearchData();"
                                                    >
                                                        {" "}
                                                        <i className="ri-equalizer-fill me-1 align-bottom" />
                                                        Filters
                                                    </button>
                                                </div> */}
                                                {/*end col*/}
                                            </div>
                                            {/*end row*/}
                                        </form>
                                    </div>
                                    {/*end card-body*/}
                                    <div className="card-body px-0 customUserListTableBody mx-0">
                                        <div className="table-responsive mb-4">
                                            <table
                                                className={`${styles['table-ctm']} table align-middle table-nowrap mb-0`}
                                                id="tasksTable"
                                            >
                                                <thead className={`${styles['table-head']} table-light text-muted`}>
                                                    <tr>
                                                        <th >
                                                            ID
                                                        </th>
                                                        <th >
                                                            Usernames
                                                        </th>
                                                        <th >
                                                            User Detail
                                                        </th>

                                                        <th >
                                                            Role
                                                        </th>
                                                        <th >
                                                            Status
                                                        </th>
                                                        <th >
                                                            Action
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody className={`${styles['crm-lists']} list form-check-all`}>
                                                    {list && count != 0
                                                        ? list.map((item, index) => {
                                                            return (< tr >
                                                                <td className="id">

                                                                    {'RJZCU' + item.USER_ID}
                                                                </td>
                                                                <td className="project_name">
                                                                    {item.USER_NAME}
                                                                </td>
                                                                <td>
                                                                    <table>
                                                                        <tr>
                                                                            <td>Name:</td>
                                                                            <td>{item.USER_NAME}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Email:</td>
                                                                            <td>{item.EMAIL}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Phone:</td>
                                                                            <td>{item.PHONE}</td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                                <td className="project_name">
                                                                    {item.ROLE_NAME}
                                                                </td>

                                                                <td className="status">
                                                                    {item.STATUS === 'A' ? <span className={`${styles['active-badge']}`}>
                                                                        ACTIVE
                                                                    </span> : <span className={`${styles['inactive-badge']}`}>
                                                                        INACTIVE
                                                                    </span>}
                                                                </td>
                                                                <td>
                                                                    <div className="flex-shrink-0 ms-4">
                                                                        <ul className="list-inline mb-0">

                                                                            <li className="list-inline-item">
                                                                                <Link className="edit-item-btn" to={constant.companyUserComponent.editUser.url.replace(':id', item.USER_ID)} >
                                                                                    <i className="ri-pencil-fill align-bottom me-2 text-muted" />
                                                                                </Link>
                                                                            </li>
                                                                            <li className="list-inline-item">
                                                                                <a className="remove-item-btn" href="javascript:void(0)" onClick={() => { props.onChangeStatus({ USER_ID: item.USER_ID, STATUS: item.STATUS === 'A' ? 'I' : 'A' }) }}>
                                                                                    <i className={`${item.STATUS === 'I' ? 'ri-login-box-fill' : 'ri-delete-bin-fill'} align-bottom me-2 text-muted`} />

                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>

                                                                </td>

                                                            </tr>)
                                                        }) : ''}
                                                </tbody>
                                            </table>
                                            {/*end table*/}
                                            {count === 0 && <div className="noresult" >
                                                <div className="text-center">
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/msoeawqm.json"
                                                        trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a"
                                                        style={{ width: 75, height: 75 }}
                                                    />
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">
                                                        We've searched more than 200k+ tasks We did not find any tasks
                                                        for you search.
                                                    </p>
                                                </div>
                                            </div>}
                                        </div>
                                        <Pagination
                                            totalCount={count}
                                            limit={10}
                                            currentPage={currentPage}
                                            range={5}
                                            onPageChange={(page) => { setCurrentPage(page) }}
                                        />
                                    </div>
                                    {/*end card-body*/}
                                </div>
                                {/*end card*/}
                            </div>
                            {/*end col*/}
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment >
    )
}

export default withRouter(Listuser)