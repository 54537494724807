import React, { useState } from 'react'
import CanididateDetailDrawerModal from '../../candidates/canididateDetailModal';
import DrawerModal from '../../../common/htmlDrawerModal'
import Pagination from '../../../common/Pagination'
import moment from 'moment';
import Edit from "../../candidates/editModal";
import Modal from '../../../common/htmlModal';
import UpdateHiringStatusModal from '../../jobs/jobApplications/updateHiringStatusModal';
import EmailMessageModal from '../../../common/EmailMessageModal';

import { ToastContainer, toast } from 'react-toastify';
import { v3_send_job_applications_mail, v3_update_hiring_status } from '../../../../actions/v3/jobApplication';
import { v3_edit_candidate } from '../../../../actions/v3/candidates';
const List = ({ list, count, currentPage, setCurrentPage, setSearchQuery, refresh, setRefresh, hideHiringStatusEdit, hideSearchBar }) => {
    const [showCandidateDetailDrawerModal, setShowCandidateDetailDrawerModal] = useState(null)
    const [showUpdateHiringStatusModal, setShowUpdateHiringStatusModal] = useState(null)
    const [showEditModal, setShowEditModal] = useState(null);
    const [showEmailModal, setShowEmailModal] = useState(null);


    const [searchState, setSearchState] = useState({
        KEYWORD: {
            name: "KEYWORD",
            value: "",
            options: "",
            visible: false,
            error: "",
            isRequired: false,
        },
        FROM_DATE: {
            name: "FROM_DATE",
            value: '',
            options: "",

            visible: false,
            error: "",
            isRequired: false,
        },
        TO_DATE: {
            name: "TO_DATE",
            value: '',
            options: "",

            visible: false,
            error: "",
            isRequired: false,
        },
    });
    const onReset = () => {
        setSearchState({
            KEYWORD: {
                name: "KEYWORD",
                value: "",
                options: "",

                visible: false,
                error: "",
                isRequired: false,
            },
            FROM_DATE: {
                name: "FROM_DATE",
                value: '',
                options: "",

                visible: false,
                error: "",
                isRequired: false,
            },
            TO_DATE: {
                name: "TO_DATE",
                value: '',
                options: "",

                visible: false,
                error: "",
                isRequired: false,
            }
        });
        const model = {
            KEYWORD: '',
            FROM_DATE: '',
            TO_DATE: '',
        };
        setCurrentPage(1)
        setSearchQuery(model);
    };
    const onSearch = () => {
        const model = {
            KEYWORD: searchState.KEYWORD.value,
            FROM_DATE: searchState.FROM_DATE.value,
            TO_DATE: searchState.TO_DATE.value,
        };
        setCurrentPage(1)

        setSearchQuery(model);
    };
    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchState((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
            },
        }));
    };

    const onSendEmailMEssage = (model) => {
        model.APPLICATION_ID = showEmailModal.APPLICATION_ID
        v3_send_job_applications_mail(model).then((res) => {
            if (res.status) {
                toast.success('Message Send Successfully')
                setShowEmailModal(null)
            }
            else {
                toast.error(res.error)

            }
        })
    }
    const onUpdateHiringStatus = (model) => {
        v3_update_hiring_status({ ...model, APPLICATION_ID: showUpdateHiringStatusModal.APPLICATION_ID }).then((res) => {
            if (res.status) {
                toast.success('Hiring Status Changed');
                setShowUpdateHiringStatusModal(null);
                setRefresh(!refresh)
            }
            else {
                toast.error(res.error)
            }
        })
    }

    const onEdit = (model) => {
        v3_edit_candidate(model).then((res) => {
            if (res.status) {
                setShowEditModal(false)
                toast.success('Candidate Lead Saved')
                setRefresh(!refresh)
            }
            else {
                toast.error(res.error)
            }
        })
    }
    return (
        <React.Fragment>
            <ToastContainer />
            <DrawerModal
                isOpen={showCandidateDetailDrawerModal}
                onClose={() => {
                    setShowCandidateDetailDrawerModal(false);
                }}
            >
                {showCandidateDetailDrawerModal && (
                    <CanididateDetailDrawerModal
                        candidateDetails={showCandidateDetailDrawerModal}
                    />
                )}
            </DrawerModal>

            <Modal
                isOpen={showUpdateHiringStatusModal}
                onClose={() => { setShowUpdateHiringStatusModal(null) }}
                header={'Update Hiring Status'}
            >
                {
                    showUpdateHiringStatusModal && <UpdateHiringStatusModal
                        onSave={(id) => { onUpdateHiringStatus(id) }}

                    />
                }
            </Modal>
            <Modal
                isOpen={showEmailModal}
                onClose={() => { setShowEmailModal(null) }}
                header={'New Message'}
            >
                {
                    showEmailModal && <EmailMessageModal
                        TO={showEmailModal.EMAIL}
                        onSendEmailMEssage={onSendEmailMEssage}
                    />
                }
            </Modal>
            <Modal isOpen={showEditModal} header={'Edit Candidate Lead Details'}
                onClose={() => { setShowEditModal(null) }}
            >
                <Edit
                    data={showEditModal}
                    onClose={() => { setShowEditModal(null) }}
                    onSubmit={(model) => { onEdit(model) }}
                />
            </Modal>
            <div className="row">
                <div className="col-lg-12 canditateSerachMain">
                    <div className="card">
                        {!hideSearchBar && < div className="card-header border-0">
                            <div className="row g-4 align-items-center">
                                <div className="col-sm-3">
                                    <input
                                        className="form-control"
                                        type='text'
                                        name={searchState.KEYWORD.name}
                                        value={searchState.KEYWORD.value}
                                        onChange={onChange}
                                        placeholder='Candidate Name, Email, Phone ...'
                                    />
                                </div>

                                <div className="col-sm-auto">
                                    <div className="hstack gap align-center">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                onReset();
                                            }}
                                            className="btn btn-danger add-btn"
                                        >
                                            Reset
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                onSearch();
                                            }}
                                            className="btn btn-success add-btn ms-1"
                                        >
                                            <i className="ri-search-line search-icon align-bottom me-1" />
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div class="col-md-12 mainclientStyleSection">
                            <div className="card mb-0">

                                <div className="card-body">
                                    {list && list.map((item) => {
                                        return (
                                            <a class="clientSection">
                                                <div class="row">
                                                    <div class="col-md-3 clientSectionLeft">
                                                        <h3 className="mb-0">
                                                            {item.FULL_NAME}
                                                            {/* <span className="text-success fs-12 ms-2" onClick={() => {
                                        setShowCandidateDetailDrawerModal(item);
                                    }}> View Full Profile </span> */}
                                                            <span className="">
                                                                {item.TOTAL_EXP_YEARS && <h6> <i class="ri-suitcase-fill" />&nbsp;{item.TOTAL_EXP_YEARS}&nbsp;&nbsp;</h6>}
                                                                {item.CURRENT_CTC && <h6> <i class="ri-money-dollar-box-fill" />&nbsp;{item.CURRENT_CTC}&nbsp;&nbsp;</h6>}
                                                                {item.WORKING_LOCATION && <h6> <i class="ri-map-pin-fill" />&nbsp;{item.WORKING_LOCATION}&nbsp;&nbsp;</h6>}
                                                            </span>
                                                        </h3>

                                                    </div>
                                                    <div class="col-md-7 clientSectionRight">
                                                        <p>
                                                            <i class="ri-time-line"></i>{" "}
                                                            Company : {item.COMPANY_NAME}
                                                        </p>
                                                        <p>
                                                            <i class="ri-time-line"></i>{" "}
                                                            Process : {item.JOB_TITLE}
                                                        </p>
                                                        {item.OFFERED_DATE && < p >
                                                            <i class="ri-time-line"></i>{" "}
                                                            Offer Release Date : {moment(item.OFFERED_DATE).format('DD MMM YYYY')}
                                                        </p>}
                                                        {item.EXPECTED_DOJ && < p >
                                                            <i class="ri-time-line"></i>{" "}
                                                            Expected Date of Joining : {moment(item.EXPECTED_DOJ).format('DD MMM YYYY')}
                                                        </p>}
                                                        {item.ACTUAL_DOJ && < p >
                                                            <i class="ri-time-line"></i>{" "}
                                                            Date of Joining : {moment(item.ACTUAL_DOJ).format('DD MMM YYYY')}
                                                        </p>}
                                                        {item.REVENUE_GENERATED && < p >
                                                            <i class="ri-time-line"></i>{" "}
                                                            Revenue Generated : {item.REVENUE_GENERATED}
                                                        </p>}

                                                        {item.DATE_OF_SELECT && <p>
                                                            <i class="ri-time-line"></i>{" "}
                                                            Date of Selection : {moment(item.DATE_OF_SELECT).format('DD MMM YYYY')}
                                                        </p>}
                                                        <p>
                                                            <i class="ri-time-line"></i>{" "}
                                                            Recruiter Name : {item.RECRUITER_NAME}
                                                        </p>
                                                        <p>
                                                            <i class="ri-time-line"></i>{" "}
                                                            Last Updated On : {item.MODIFIED_ON ? moment(item.MODIFIED_ON).format('DD MMM YYYY') : 'Not Available'}
                                                        </p>


                                                    </div>
                                                    <div class="col-md-2 clientSectionRight">



                                                        <p className="text-success" onClick={() => {
                                                            setShowCandidateDetailDrawerModal(item);
                                                        }}> View Full Profile </p>


                                                        <p
                                                            onClick={() => { setShowEmailModal(item) }}
                                                        >
                                                            <i class="ri-question-answer-line"></i>
                                                        </p>


                                                        <p onClick={() => { window.location.href = `tel:${item.PHONE}` }}>
                                                            <i class="ri-phone-fill"></i>
                                                        </p>



                                                        {!hideHiringStatusEdit && <p
                                                            onClick={() => {
                                                                setShowUpdateHiringStatusModal(item)
                                                            }}>
                                                            <i class="ri-edit-box-line"></i>
                                                        </p>}
                                                        <p href="javascript:void(0)" onClick={() => {
                                                            setShowEditModal(item)
                                                        }}>
                                                            <i class="ri-edit-line"></i>
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        )
                                    })}
                                    {
                                        list && count > 0 && <div className="customPaginationStyle">
                                            <Pagination
                                                totalCount={count}
                                                limit={20}
                                                range={5}
                                                currentPage={Number(currentPage)}
                                                onPageChange={(page) => { setCurrentPage(page) }}
                                            />
                                        </div>
                                    }


                                    {
                                        list && count == 0 &&
                                        <div class="card">
                                            <div className="noresult mb-3 mt-3" >
                                                <div className="text-center">
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/msoeawqm.json"
                                                        trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a"
                                                        style={{ width: 75, height: 75 }}
                                                    ></lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">
                                                        We've searched more than 150+ Orders We did not find any orders for
                                                        you search.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment >
    )
}

export default List