import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { v1_course_specialization, v1_education_qualification, v1_qualification } from '../../../actions/v1/master_data'
import { v3_call_directory_assignee, v3_candidate_bulk_upload, v3_candidate_bulk_upload_duplicate_check, v3_candidate_preferred_role_options, v3_lead_owners } from '../../../actions/v3/candidates';
import { getStorage, validateArrayFunctionalForm, validateFunctionalForm } from '../../../utils';
import CircleLoader from '../../../components/common/Loader/circleLoader'
import BarLoader from '../../../components/common/Loader/barLoader'
import { ToastContainer, toast } from 'react-toastify';
import constant from '../../../constant';
import styles from '././candidate.module.css'

const Add = (props) => {

    const [state, setState] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [showBarLoader, setShowBarLoader] = useState(false)
    const [assigneeOptions, setAssigneeOptions] = useState([])



    const handleFileUpload = (event) => {
        setShowLoader(true)
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            debugger
            // Map JSON data to candidateForm structure with error handling
            const HIGHEST_QUALIFICATION_OPTIONS = await v1_qualification()
            const PREFERRED_ROLE_OPTIONS = await v3_candidate_preferred_role_options()
            const LEAD_OWNER_OPTIONS = await v3_lead_owners()
            const ASSIGNEE_OPTIONS = await v3_call_directory_assignee()
            setAssigneeOptions(ASSIGNEE_OPTIONS.result)
            const candidateFormData = jsonData.map((item) => {
                const candidate = {};

                try {
                    candidate.FULL_NAME = { name: 'FULL_NAME', value: item['Full Name'] || '', error: '', isRequired: true };
                    candidate.EMAIL = { name: 'EMAIL', value: item.Email || '', error: '', isRequired: true };
                    candidate.PHONE = { name: 'PHONE', value: (parseFloat(item.Phone) || '').toString(), error: '', isRequired: true };
                    candidate.WORKING_LOCATION = { name: 'WORKING_LOCATION', value: item['Working Location'] || '', error: '', isRequired: false };
                    candidate.RESIDENTIAL_LOCATION = { name: 'RESIDENTIAL_LOCATION', value: item['Residential Location'] || '', error: '', isRequired: false };
                    candidate.CURRENT_COMPANY = { name: 'CURRENT_COMPANY', value: item['Current Company'] || '', error: '', isRequired: false };
                    candidate.CURRENT_CTC = { name: 'CURRENT_CTC', value: item['Current CTC'] || '', error: '', isRequired: false };
                    candidate.EXPECTED_CTC = { name: 'EXPECTED_CTC', value: item['Expected CTC'] || '', error: '', isRequired: false };
                    candidate.SKILLS = { name: 'SKILLS', value: item.Skills || '', error: '', isRequired: false };

                    // Handle potential missing or invalid numeric fields gracefully (e.g., convert to numbers or empty strings)
                    candidate.TOTAL_EXP_YEARS = {
                        name: 'TOTAL_EXP_YEARS',
                        value: (parseFloat(item['Total Expereince (Years)']) || '').toString(),
                        error: '', isRequired: false
                    };
                    candidate.TOTAL_EXP_MONTHS = {
                        name: 'TOTAL_EXP_MONTHS',
                        value: (parseFloat(item['Total Expereince (Month)']) || '').toString(),
                        error: '', isRequired: false
                    };
                    candidate.COLLEGE_NAME = { name: 'COLLEGE_NAME', value: item['College Name'] || '', error: '', isRequired: false };
                    candidate.PASS_OUT_YEAR = { name: 'PASS_OUT_YEAR', value: item['Pass out year'] || '', error: '', isRequired: false };
                    candidate.NOTE = { name: 'NOTE', value: item['Note'] || '', error: '', isRequired: false };
                    candidate.CURRENT_DESIGNATION = { name: 'CURRENT_DESIGNATION', value: item['Current Designation'] || '', error: '', isRequired: false };
                    candidate.PREFERRED_ROLE = { name: 'PREFERRED_ROLE', value: '', options: PREFERRED_ROLE_OPTIONS.result, error: '', isRequired: false };
                    candidate.HIGHEST_QUALIFICATION = { name: 'HIGHEST_QUALIFICATION', value: '', options: HIGHEST_QUALIFICATION_OPTIONS.result, error: '', isRequired: false };
                    candidate.HIGHEST_EDUCATION_QUALIFICATION = { name: 'HIGHEST_EDUCATION_QUALIFICATION', value: '', options: [], error: '', isRequired: false };
                    candidate.COURSE_SPECIALIZATION = { name: 'COURSE_SPECIALIZATION', value: '', options: [], error: '', isRequired: false };
                    candidate.RESUME_FILE_NAME = { name: 'RESUME_FILE_NAME', value: '', error: '', isRequired: false };


                    candidate.NOTICE_PERIOD = { name: 'NOTICE_PERIOD', value: '', error: '', isRequired: false };
                    candidate.SOURCE = { name: 'SOURCE', value: '', error: '', isRequired: false };
                    candidate.RATING = {
                        name: 'RATING', value:
                            (parseFloat(item['Rating']) || '').toString()
                        , error: '', isRequired: false
                    };
                    candidate.LEAD_OWNER = { name: 'LEAD_OWNER', value: item['LEAD_OWNER'] || '', options: LEAD_OWNER_OPTIONS.result, error: '', isRequired: false };
                    candidate.ASSIGNEE = { name: 'ASSIGNEE', value: '', options: ASSIGNEE_OPTIONS.result, error: '', isRequired: false };
                    return candidate;
                } catch (error) {
                    console.error('Error processing JSON data item:', error);
                    return null;
                }
            });

            // Filter out potential null values from mapping errors
            const filteredCandidateFormData = candidateFormData.filter(Boolean);

            setState(filteredCandidateFormData);
            setShowLoader(false)
        };

        reader.readAsArrayBuffer(file);
    };



    const downloadSampleExcel = async () => {
        // Fetch dropdown options from API

        // Sample data
        const data = [{
            "Full Name": "Dheeraj",
            "Email": "dheerajbothra090491@gmail.com",
            "Phone": "9988776655",
            "Working Location": "Noida",
            "Residential Location": "Noida",
            "Current Company": "Noida",
            "Current CTC": "12",
            "Expected CTC": "12",
            "Skills": "Node.js ",
            "Total Expereince (Years)": "12",
            "Total Expereince (Month)": "12",
            "College Name": null,
            "Pass out year": null,
            "Note": null,
            "Current Designation": null,
            "Preferred Role": 'Voice',
            "Rating": 2.5,
        }];

        // Create a new worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Add the worksheet to a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Write the workbook to a buffer
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Convert buffer to Blob
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        // Save the Blob as a file
        saveAs(blob, 'sample.xlsx');
    };

    const handleInputChange = async (index, e) => {
        const { name, value } = e.target;
        if (name === 'RESUME_FILE_NAME') {
            const file = e.target.files[0];

            try {
                setShowLoader(true)
                const base64String = await fileToBase64(file);
                setState(prevState => {
                    const newState = [...prevState];
                    newState[index][name].value = base64String;
                    return newState;
                });
                setShowLoader(false)
            } catch (error) {
                console.error('Error converting file to Base64:', error);
            }
        }
        else {




            if (name === 'HIGHEST_QUALIFICATION') {
                setShowLoader(true)
                v1_education_qualification(value).then((res) => {
                    if (res.status) {
                        setState(prevState => {
                            const newState = [...prevState];
                            newState[index][name].value = value;
                            newState[index]['HIGHEST_EDUCATION_QUALIFICATION'].options = res.result;
                            return newState;
                        });
                        setShowLoader(false)
                    }
                })
            }
            else if (name === 'HIGHEST_EDUCATION_QUALIFICATION') {
                setShowLoader(true)
                v1_course_specialization(value).then((res) => {
                    if (res.status) {
                        setState(prevState => {
                            const newState = [...prevState];
                            newState[index][name].value = value;
                            newState[index]['COURSE_SPECIALIZATION'].options = res.result;
                            return newState;
                        });
                        setShowLoader(false)
                    }
                })
            }
            else {

                setState(prevState => {
                    const newState = [...prevState];
                    newState[index][name].value = value;
                    return newState;
                });
            }
        }
    };

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result; // Get the Base64 string from data URL
                resolve(base64String);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file); // Read the file as data URL
        });
    };
    const [finalState, setFinalState] = useState(null)

    const onVerify = () => {
        if (
            validateArrayFunctionalForm(state, setState)
        ) {
            setShowLoader(true)
            const email = state?.map((item) => { return item.EMAIL.value })
            const phone = state?.map((item) => { return item.PHONE.value })
            v3_candidate_bulk_upload_duplicate_check({ EMAIL: email, PHONE: phone }).then((res) => {
                if (res.status) {
                    const result = res.result;
                    const filteredResult = state?.map((item) => {
                        return {
                            FULL_NAME: { value: item.FULL_NAME.value, isDuplicate: false },
                            EMAIL: { value: item.EMAIL.value, isDuplicate: result.some((candidate) => candidate.EMAIL === item.EMAIL.value) },
                            PHONE: { value: item.PHONE.value, isDuplicate: result.some((candidate) => candidate.PHONE === item.PHONE.value) },
                            ASSIGNEE: { value: item.ASSIGNEE.value, isDuplicate: false },
                            WORKING_LOCATION: { value: item.WORKING_LOCATION.value, isDuplicate: false },
                            RESIDENTIAL_LOCATION: { value: item.RESIDENTIAL_LOCATION.value, isDuplicate: false },
                            CURRENT_COMPANY: { value: item.CURRENT_COMPANY.value, isDuplicate: false },
                            CURRENT_CTC: { value: item.CURRENT_CTC.value, isDuplicate: false },
                            EXPECTED_CTC: { value: item.EXPECTED_CTC.value, isDuplicate: false },
                            SKILLS: { value: item.SKILLS.value, isDuplicate: false },
                            TOTAL_EXP_YEARS: { value: item.TOTAL_EXP_YEARS.value, options: [], isDuplicate: false },
                            HIGHEST_QUALIFICATION: { value: item.HIGHEST_QUALIFICATION.value, options: item.HIGHEST_QUALIFICATION.options, isDuplicate: false },
                            HIGHEST_EDUCATION_QUALIFICATION: { value: item.HIGHEST_EDUCATION_QUALIFICATION.value, options: item.HIGHEST_EDUCATION_QUALIFICATION.options, isDuplicate: false },
                            COURSE_SPECIALIZATION: { value: item.COURSE_SPECIALIZATION.value, options: item.COURSE_SPECIALIZATION.options, isDuplicate: false },
                            RESUME_FILE_NAME: { value: item.RESUME_FILE_NAME.value, isDuplicate: false },
                            NOTICE_PERIOD: { value: item.NOTICE_PERIOD.value, isDuplicate: false },
                            SOURCE: { value: item.SOURCE.value, isDuplicate: false },
                            RATING: { value: item.RATING.value, isDuplicate: false },
                            COLLEGE_NAME: { value: item.COLLEGE_NAME.value, isDuplicate: false },
                            PASS_OUT_YEAR: { value: item.PASS_OUT_YEAR.value, isDuplicate: false },
                            NOTE: { value: item.NOTE.value, isDuplicate: false },
                            CURRENT_DESIGNATION: { value: item.CURRENT_DESIGNATION.value, isDuplicate: false },
                            TOTAL_EXP_MONTHS: { value: item.TOTAL_EXP_MONTHS.value, isDuplicate: false },
                            LEAD_OWNER: { value: item.LEAD_OWNER.value, isDuplicate: false },
                            PREFERRED_ROLE_ID: { value: item.PREFERRED_ROLE.value, options: item.PREFERRED_ROLE.options, isDuplicate: false },
                        }
                    });
                    setFinalState(filteredResult)
                    setShowLoader(false)
                }
            })

        }
    }

    const onRemoveRow = (index) => {
        // Ensure index is valid
        if (index >= 0 && index < state.length) {
            setShowLoader(true)

            // Create a copy of the state array
            const newState = [...state];
            const newFinalState = [...finalState];
            // Remove the item at the specified index
            newState.splice(index, 1);
            newFinalState.splice(index, 1);
            // Update the state with the new array
            setState(newState);
            setFinalState(newFinalState)
            setShowLoader(false)
        } else {
            console.error('Invalid index:', index);
        }
    }

    const onEdit = () => {
        setFinalState(null)
    }

    const onSubmit = () => {
        setShowBarLoader(true)
        const { FULL_NAME } = getStorage(constant.keys.companyUserDetails);
        v3_candidate_bulk_upload({ RECRUITER_NAME: FULL_NAME, data: JSON.stringify(finalState.filter((item) => { if (!item.EMAIL.isDuplicate && !item.PHONE.isDuplicate) { return item } })) }).then((res) => {
            setState(null)
            setFinalState(null)
            setShowBarLoader(false)
            toast(`${res.result} records have been saved successfully`)

        })
    }
    return (
        <React.Fragment>
            <ToastContainer />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {showLoader && <CircleLoader />}
                        {showBarLoader && <BarLoader />}

                        <div className="row">
                            <div className="col-12">
                                <div className={` ${styles.ml} page-title-box d-sm-flex align-items-center justify-content-between`}>
                                    <h4 className="mb-sm-0">Candidate Bulk Upload</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Candidate Management</a>
                                            </li>
                                            <li className="breadcrumb-item active">Bulk Upload</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className={`${styles.cardBulkUpload} card`}>
                                    <div className={`${styles.cardHeaderBox}  card-header`}>
                                        <div className="live-preview" >
                                            <div className="row gy-4">
                                                <div className="col-lg-12 mb-3">
                                                    {/* <div>
                                                        <label className="form-label">
                                                            Upload Excel
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <input
                                                            type="file"
                                                            className={"form-control"}
                                                            onChange={handleFileUpload}
                                                        />
                                                    </div> */}

                                                    {/* <span className='text-success ms-1' onClick={() => {
                                                        downloadSampleExcel()
                                                    }}>Download Sample File</span> */}
                                                    {/* <p className='topparaBox'>Add your documents here, and you can upload up to 5 files max</p> */}
                                                    <div class="containerbox">


                                                        <img src="/assets/images/FolderIcon.png" />

                                                        <div className='inputParaBox'>Attach Files by dragging your file(s) to start uploading</div>
                                                        <div className='orSeprestionbox'><h4>or</h4> </div>
                                                        <label class="btn" for="upload">
                                                            Browse Files
                                                            <input
                                                                type="file"
                                                                id="upload"
                                                                class="isVisuallyHidden"
                                                                onChange={handleFileUpload}
                                                            />
                                                        </label>


                                                    </div>
                                                    {/* <input type="file" accept=".xlsx,.xls,.csv" /> */}
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <p className={styles.paraBottom}>
                                                            Only support Excel (.xlsx, .xls) and CSV files
                                                        </p>
                                                        <p
                                                            className={`${styles.paraBottom} text-success text-end`}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={downloadSampleExcel}
                                                        >
                                                            Download Sample File
                                                        </p>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {state && state.length > 0 ? <div className={`${styles.cardBodyBox} card-body`}>
                                        <div className="live-preview" >
                                            <div className={`${styles.cardheadertitle} ${styles.mr0} row`}>
                                                <div className={`${styles.bulkleftheadingBox} col-md-7`}>
                                                    <h4>Add Bulk Candidates</h4>
                                                </div>
                                                <div className={`${styles.rightheaderbox} ${styles.textAlignRight} col-md-5`}>
                                                    {!finalState && state && state.length > 0 ? <React.Fragment>
                                                        <div className="d-flex align-items-center justify-content-end">
                                                            {/* Save and Verify Button */}
                                                            <button
                                                                type="submit"
                                                                className="btn me-2"
                                                                onClick={() => {
                                                                    onVerify();
                                                                }}
                                                            >
                                                                <img
                                                                    src="/assets/images/quikAdd.png"
                                                                    alt="quick"
                                                                />{" "}
                                                                Save and Verify
                                                            </button>

                                                            {/* Dropdown for Select Bulk Assignee */}
                                                            <div className="dropdown">
                                                                <button
                                                                    className="btn dropdown-toggle"
                                                                    type="button"
                                                                    id="bulkAssigneeDropdown"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                >
                                                                    <img
                                                                        src="/assets/images/groupAdmin.png"
                                                                        alt="quick"
                                                                        style={{ marginRight: "8px" }}
                                                                    />
                                                                    Select Bulk Assignee
                                                                </button>
                                                                <ul
                                                                    className="dropdown-menu"
                                                                    aria-labelledby="bulkAssigneeDropdown"
                                                                >
                                                                    <li>
                                                                        <select
                                                                            className="form-select"
                                                                            style={{ width: "100%" }}
                                                                            onChange={(e) => {
                                                                                const newState = state.map((item) => ({
                                                                                    ...item,
                                                                                    ASSIGNEE: {
                                                                                        ...item.ASSIGNEE,
                                                                                        value: e.target.value,
                                                                                    },
                                                                                }));
                                                                                setState(newState);
                                                                            }}
                                                                        >
                                                                            <option value="">Select Bulk Assignee</option>
                                                                            {assigneeOptions &&
                                                                                assigneeOptions.map((i) => (
                                                                                    <option key={i.USER_ID} value={i.USER_ID}>
                                                                                        {i.FULL_NAME}
                                                                                    </option>
                                                                                ))}
                                                                        </select>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>


                                                        : ''}

                                                    {finalState && finalState.length > 0 ? <React.Fragment >
                                                        <button className="btn btn-success me-2" onClick={() => { onEdit() }}>
                                                            Edit                                                       </button>
                                                        <button className="btn btn-success" onClick={() => { onSubmit() }}>
                                                            Submit                                                       </button>
                                                    </React.Fragment> : ''}
                                                </div>
                                            </div>
                                            <div className={`${styles.tableResponsive} table-responsive`}>
                                                <table className="table table-nowrap mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">Full Name</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Phone</th>
                                                            <th scope="col">Assignee</th>
                                                            <th scope="col">Work Location</th>
                                                            <th scope="col">Residential Location</th>
                                                            <th scope="col">Current Company</th>
                                                            <th scope="col">Current CTC</th>
                                                            <th scope="col">Expected CTC</th>
                                                            <th scope="col">Preferred Role</th>
                                                            <th scope="col">Skills</th>
                                                            <th scope="col">Total Experience (Year)</th>
                                                            <th scope="col">Total Experience (Month)</th>
                                                            <th scope="col">College Name</th>
                                                            <th scope="col">Pass Out Year</th>
                                                            <th scope="col">Highest Qualification</th>
                                                            <th scope="col">Course</th>
                                                            <th scope="col">Specialization</th>
                                                            <th scope="col">Resume File</th>
                                                            <th scope="col">Notice Period</th>
                                                            <th scope="col">Source</th>
                                                            <th scope="col">Rating</th>
                                                            <th scope="col">Lead Owner</th>
                                                            <th scope="col">Note</th>
                                                            <th scope="col">Current Designation</th>
                                                            {finalState && <th scope="col">Delete Record</th>}

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!finalState && state && state.map((item, index) => {

                                                            return (
                                                                <tr>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={item.FULL_NAME.error && !item.FULL_NAME.value ? "form-control is-invalid" : "form-control"}
                                                                            name={item.FULL_NAME.name}
                                                                            style={{ width: 'auto' }}
                                                                            value={item.FULL_NAME.value}
                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={item.EMAIL.error && !item.EMAIL.value ? "form-control is-invalid" : "form-control"}
                                                                            name={item.EMAIL.name}
                                                                            value={item.EMAIL.value}
                                                                            style={{ width: 'auto' }}

                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="number"
                                                                            className={item.PHONE.error && !item.PHONE.value ? "form-control is-invalid" : "form-control"}
                                                                            name={item.PHONE.name}
                                                                            value={item.PHONE.value}
                                                                            style={{ width: 'auto' }}

                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <select
                                                                            className={"form-select"}
                                                                            name={item.ASSIGNEE.name}
                                                                            value={item.ASSIGNEE.value}
                                                                            style={{ width: 'auto' }}

                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                        >
                                                                            <option value=''> Select Assignee</option>
                                                                            {item.ASSIGNEE.options && item.ASSIGNEE.options.map((i) => {
                                                                                return (
                                                                                    <option value={i.USER_ID}> {i.FULL_NAME}</option>
                                                                                )
                                                                            })}

                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            name={item.WORKING_LOCATION.name}
                                                                            value={item.WORKING_LOCATION.value}
                                                                            style={{ width: 'auto' }}

                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            name={item.RESIDENTIAL_LOCATION.name}
                                                                            value={item.RESIDENTIAL_LOCATION.value}
                                                                            style={{ width: 'auto' }}

                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            name={item.CURRENT_COMPANY.name}
                                                                            style={{ width: 'auto' }}

                                                                            value={item.CURRENT_COMPANY.value}
                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            name={item.CURRENT_CTC.name}
                                                                            style={{ width: 'auto' }}

                                                                            value={item.CURRENT_CTC.value}
                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            name={item.EXPECTED_CTC.name}
                                                                            value={item.EXPECTED_CTC.value}
                                                                            style={{ width: 'auto' }}

                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <select
                                                                            className={"form-control"}
                                                                            name={item.PREFERRED_ROLE.name}
                                                                            value={item.PREFERRED_ROLE.value}
                                                                            style={{ width: 'auto' }}

                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                        >

                                                                            <option value=''> Select Preferred Role</option>
                                                                            {item.PREFERRED_ROLE.options && item.PREFERRED_ROLE.options.map((i) => {
                                                                                return (
                                                                                    <option value={i.ROLE_ID}> {i.ROLE_NAME}</option>
                                                                                )
                                                                            })}

                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            name={item.SKILLS.name}
                                                                            style={{ width: 'auto' }}

                                                                            value={item.SKILLS.value}
                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <select
                                                                            className={"form-select"}
                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                            name={item.TOTAL_EXP_YEARS.name}
                                                                            value={item.TOTAL_EXP_YEARS.value}
                                                                            style={{ width: 'auto' }}

                                                                        >

                                                                            <option value=''> Select Experience Year</option>
                                                                            <option value='Fresher'> Fresher</option>
                                                                            <option value='0 Year'> 0 Year</option>
                                                                            <option value='1 Year'> 1 Year</option>
                                                                            <option value='2 Year'> 2 Year</option>
                                                                            <option value='3 Year'> 3 Year</option>
                                                                            <option value='4 Year'> 4 Year</option>
                                                                            <option value='5 Year'> 5 Year</option>
                                                                            <option value='6 Year'> 6 Year</option>
                                                                            <option value='7 Year'> 7 Year</option>
                                                                            <option value='8 Year'> 8 Year</option>
                                                                            <option value='9 Year'> 9 Year</option>
                                                                            <option value='10 Year'> 10 Year</option>
                                                                            <option value='11 Year'> 11 Year</option>
                                                                            <option value='12 Year'> 12 Year</option>
                                                                            <option value='13 Year'> 13 Year</option>
                                                                            <option value='14 Year'> 14 Year</option>
                                                                            <option value='15 Year'> 15 Year</option>
                                                                            <option value='16 Year'> 16 Year</option>
                                                                            <option value='17 Year'> 17 Year</option>
                                                                            <option value='18 Year'> 18 Year</option>
                                                                            <option value='19 Year'> 19 Year</option>
                                                                            <option value='20 Year'> 20 Year</option>
                                                                            <option value='21 Year'> 21 Year</option>
                                                                            <option value='22 Year'> 22 Year</option>
                                                                            <option value='23 Year'> 23 Year</option>
                                                                            <option value='24 Year'> 24 Year</option>
                                                                            <option value='25 Year'> 25 Year</option>
                                                                            <option value='26 Year'> 26 Year</option>
                                                                            <option value='27 Year'> 27 Year</option>
                                                                            <option value='28 Year'> 28 Year</option>
                                                                            <option value='29 Year'> 29 Year</option>
                                                                            <option value='30 Year'> 30 Year</option>
                                                                            <option value='31 Year'> 31 Year</option>
                                                                            <option value='32 Year'> 32 Year</option>
                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <select
                                                                            type="text"
                                                                            className={"form-select"}
                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                            name={item.TOTAL_EXP_MONTHS.name}
                                                                            value={item.TOTAL_EXP_MONTHS.value}
                                                                            style={{ width: 'auto' }}

                                                                            disabled={item.TOTAL_EXP_YEARS.value === 'Fresher'}
                                                                        >

                                                                            <option value=''> Select Experience Month</option>
                                                                            <option value='0 Month'> 0 Month</option>
                                                                            <option value='1 Month'> 1 Month</option>
                                                                            <option value='2 Month'> 2 Month</option>
                                                                            <option value='3 Month'> 3 Month</option>
                                                                            <option value='4 Month'> 4 Month</option>
                                                                            <option value='5 Month'> 5 Month</option>
                                                                            <option value='6 Month'> 6 Month</option>
                                                                            <option value='7 Month'> 7 Month</option>
                                                                            <option value='8 Month'> 8 Month</option>
                                                                            <option value='9 Month'> 9 Month</option>
                                                                            <option value='10 Month'> 10 Month</option>
                                                                            <option value='11 Month'> 11 Month</option>
                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            name={item.COLLEGE_NAME.name}
                                                                            value={item.COLLEGE_NAME.value}
                                                                            style={{ width: 'auto' }}

                                                                            disabled={item.TOTAL_EXP_YEARS.value !== 'Fresher'}
                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            name={item.PASS_OUT_YEAR.name}
                                                                            value={item.PASS_OUT_YEAR.value}
                                                                            style={{ width: 'auto' }}

                                                                            disabled={item.TOTAL_EXP_YEARS.value !== 'Fresher'}
                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                        />
                                                                    </div></td>

                                                                    <td scope="col"><div>
                                                                        <select
                                                                            className={"form-select"}
                                                                            name={item.HIGHEST_QUALIFICATION.name}
                                                                            value={item.HIGHEST_QUALIFICATION.value}
                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                            style={{ width: 'auto' }}

                                                                        >
                                                                            <option value=''> Select Highest Qualification</option>
                                                                            {item.HIGHEST_QUALIFICATION.options && item.HIGHEST_QUALIFICATION.options.map((i) => {
                                                                                return (
                                                                                    <option value={i.QUALIFICATION_ID}> {i.QUALIFICATION_NAME}</option>

                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <select
                                                                            className={"form-select"}
                                                                            name={item.HIGHEST_EDUCATION_QUALIFICATION.name}
                                                                            value={item.HIGHEST_EDUCATION_QUALIFICATION.value}
                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                            style={{ width: 'auto' }}


                                                                        >
                                                                            <option value=''> Select Course</option>
                                                                            {item.HIGHEST_EDUCATION_QUALIFICATION.options && item.HIGHEST_EDUCATION_QUALIFICATION.options.map((i) => {
                                                                                return (<option
                                                                                    value={i.EDUCATION_QUALIFICATION_ID}

                                                                                >
                                                                                    {i.COURSE_STREAM}
                                                                                </option>)
                                                                            })}

                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <select
                                                                            className={"form-select"}
                                                                            name={item.COURSE_SPECIALIZATION.name}
                                                                            value={item.COURSE_SPECIALIZATION.value}
                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                            style={{ width: 'auto' }}


                                                                        >
                                                                            <option value=''> Select Specialization</option>
                                                                            {
                                                                                item.COURSE_SPECIALIZATION.options && item.COURSE_SPECIALIZATION.options.map((i) => {
                                                                                    return (<option
                                                                                        value={i.SPECIALIZATION_ID}
                                                                                    >
                                                                                        {i.SPECIALIZATION}
                                                                                    </option>)
                                                                                })

                                                                            }
                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="file"
                                                                            className={"form-control"}
                                                                            name={item.RESUME_FILE_NAME.name}
                                                                            // value={item.RESUME_FILE_NAME.value}
                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                            style={{ width: 'auto' }}


                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <select
                                                                            type="text"
                                                                            className="form-select"
                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                            style={{ width: 'auto' }}

                                                                            name={item.NOTICE_PERIOD.name}
                                                                            value={item.NOTICE_PERIOD.value}
                                                                        >
                                                                            <option value=''> Select Notice Period</option>
                                                                            <option value='Immediate Joiner'> Immediate Joiner</option>
                                                                            <option value='15 Days'> 15 Days</option>
                                                                            <option value='1 Month'> 1 Month</option>
                                                                            <option value='2 Month'> 2 Month</option>
                                                                            <option value='3 Month'> 3 Month or more</option>

                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>
                                                                        <select
                                                                            className={"form-select"}
                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                            style={{ width: 'auto' }}

                                                                            name={item.SOURCE.name}
                                                                            value={item.SOURCE.value}
                                                                        >
                                                                            <option value=''> Select Lead Source</option>
                                                                            <option value='Found It / Monster'> Found It / Monster</option>
                                                                            <option value='Naukri'> Naukri</option>
                                                                            <option value='LinkedIn'> LinkedIn</option>
                                                                            <option value='Instagram'> Instagram</option>
                                                                            <option value='apna'> apna</option>
                                                                            <option value='Campus'> Campus</option>
                                                                            <option value='Other Portal'> Other Portal</option>
                                                                            <option value='Referral'> Referral</option>
                                                                            <option value='Social Media'> Social Media</option>
                                                                            <option value='Walk-In'> Walk-In</option>
                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            name={item.RATING.name}
                                                                            style={{ width: 'auto' }}

                                                                            value={item.RATING.value}
                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>
                                                                        <select
                                                                            className={item.LEAD_OWNER.error && !item.LEAD_OWNER.value ? 'form-select is-invalid' : 'form-select'}
                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                            style={{ width: 'auto' }}
                                                                            value={item.LEAD_OWNER.value}
                                                                            name={item.LEAD_OWNER.name}
                                                                        >
                                                                            <option value=''>
                                                                                Select Lead Owner
                                                                            </option>
                                                                            {item.LEAD_OWNER.options && item.LEAD_OWNER.options.map((item) => {
                                                                                return (
                                                                                    <option key={item.USER_ID} value={item.USER_ID}>
                                                                                        {item.FULL_NAME}
                                                                                    </option>
                                                                                )
                                                                            })}
                                                                        </select>

                                                                    </div></td>


                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            name={item.NOTE.name}
                                                                            style={{ width: 'auto' }}

                                                                            value={item.NOTE.value}
                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            name={item.CURRENT_DESIGNATION.name}
                                                                            value={item.CURRENT_DESIGNATION.value}
                                                                            style={{ width: 'auto' }}

                                                                            onChange={(e) => handleInputChange(index, e)}

                                                                        />
                                                                    </div></td>
                                                                </tr>)
                                                        })}

                                                        {finalState && finalState.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={item.FULL_NAME.isDuplicate ? "form-control is-invalid" : "form-control"}
                                                                            style={{ width: 'auto' }}

                                                                            value={item.FULL_NAME.value}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={item.EMAIL.isDuplicate ? "form-control is-invalid" : "form-control"}
                                                                            value={item.EMAIL.value}
                                                                            style={{ width: 'auto' }}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="number"
                                                                            className={item.PHONE.isDuplicate ? "form-control is-invalid" : "form-control"}
                                                                            style={{ width: 'auto' }}

                                                                            value={item.PHONE.value}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <select
                                                                            className={"form-select"}
                                                                            // name={item.ASSIGNEE.name}
                                                                            disabled={true}
                                                                            value={item.ASSIGNEE.value}
                                                                            style={{ width: 'auto' }}

                                                                        // onChange={(e) => handleInputChange(index, e)}

                                                                        >
                                                                            <option value=''> Select Assignee</option>
                                                                            {item.ASSIGNEE.options && item.ASSIGNEE.options.map((i) => {
                                                                                return (
                                                                                    <option value={i.USER_ID}> {i.FULL_NAME}</option>
                                                                                )
                                                                            })}

                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            value={item.WORKING_LOCATION.value}
                                                                            style={{ width: 'auto' }}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            value={item.RESIDENTIAL_LOCATION.value}
                                                                            style={{ width: 'auto' }}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            value={item.CURRENT_COMPANY.value}
                                                                            style={{ width: 'auto' }}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            value={item.CURRENT_CTC.value}
                                                                            style={{ width: 'auto' }}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            value={item.EXPECTED_CTC.value}
                                                                            style={{ width: 'auto' }}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <select
                                                                            className={"form-control"}
                                                                            value={item.PREFERRED_ROLE_ID.value}
                                                                            style={{ width: 'auto' }}

                                                                        >

                                                                            <option value=''> Select Preferred Role</option>
                                                                            {item.PREFERRED_ROLE_ID.options && item.PREFERRED_ROLE_ID.options.map((i) => {
                                                                                return (
                                                                                    <option value={i.ROLE_ID}> {i.ROLE_NAME}</option>
                                                                                )
                                                                            })}

                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            value={item.SKILLS.value}
                                                                            style={{ width: 'auto' }}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <select
                                                                            className={"form-select"}
                                                                            style={{ width: 'auto' }}

                                                                            value={item.TOTAL_EXP_YEARS.value}
                                                                        >

                                                                            <option value=''> Select Experience Year</option>
                                                                            <option value='Fresher'> Fresher</option>
                                                                            <option value='0 Year'> 0 Year</option>
                                                                            <option value='1 Year'> 1 Year</option>
                                                                            <option value='2 Year'> 2 Year</option>
                                                                            <option value='3 Year'> 3 Year</option>
                                                                            <option value='4 Year'> 4 Year</option>
                                                                            <option value='5 Year'> 5 Year</option>
                                                                            <option value='6 Year'> 6 Year</option>
                                                                            <option value='7 Year'> 7 Year</option>
                                                                            <option value='8 Year'> 8 Year</option>
                                                                            <option value='9 Year'> 9 Year</option>
                                                                            <option value='10 Year'> 10 Year</option>
                                                                            <option value='11 Year'> 11 Year</option>
                                                                            <option value='12 Year'> 12 Year</option>
                                                                            <option value='13 Year'> 13 Year</option>
                                                                            <option value='14 Year'> 14 Year</option>
                                                                            <option value='15 Year'> 15 Year</option>
                                                                            <option value='16 Year'> 16 Year</option>
                                                                            <option value='17 Year'> 17 Year</option>
                                                                            <option value='18 Year'> 18 Year</option>
                                                                            <option value='19 Year'> 19 Year</option>
                                                                            <option value='20 Year'> 20 Year</option>
                                                                            <option value='21 Year'> 21 Year</option>
                                                                            <option value='22 Year'> 22 Year</option>
                                                                            <option value='23 Year'> 23 Year</option>
                                                                            <option value='24 Year'> 24 Year</option>
                                                                            <option value='25 Year'> 25 Year</option>
                                                                            <option value='26 Year'> 26 Year</option>
                                                                            <option value='27 Year'> 27 Year</option>
                                                                            <option value='28 Year'> 28 Year</option>
                                                                            <option value='29 Year'> 29 Year</option>
                                                                            <option value='30 Year'> 30 Year</option>
                                                                            <option value='31 Year'> 31 Year</option>
                                                                            <option value='32 Year'> 32 Year</option>
                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <select
                                                                            type="text"
                                                                            className={"form-select"}
                                                                            value={item.TOTAL_EXP_MONTHS.value}
                                                                            style={{ width: 'auto' }}

                                                                        >

                                                                            <option value=''> Select Experience Month</option>
                                                                            <option value='0 Month'> 0 Month</option>
                                                                            <option value='1 Month'> 1 Month</option>
                                                                            <option value='2 Month'> 2 Month</option>
                                                                            <option value='3 Month'> 3 Month</option>
                                                                            <option value='4 Month'> 4 Month</option>
                                                                            <option value='5 Month'> 5 Month</option>
                                                                            <option value='6 Month'> 6 Month</option>
                                                                            <option value='7 Month'> 7 Month</option>
                                                                            <option value='8 Month'> 8 Month</option>
                                                                            <option value='9 Month'> 9 Month</option>
                                                                            <option value='10 Month'> 10 Month</option>
                                                                            <option value='11 Month'> 11 Month</option>
                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            value={item.COLLEGE_NAME.value}
                                                                            style={{ width: 'auto' }}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>
                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            style={{ width: 'auto' }}
                                                                            value={item.PASS_OUT_YEAR.value}
                                                                        />
                                                                    </div></td>

                                                                    <td scope="col"><div>
                                                                        <select
                                                                            className={"form-select"}
                                                                            value={item.HIGHEST_QUALIFICATION.value}
                                                                            style={{ width: 'auto' }}

                                                                        >
                                                                            <option value=''> Select Highest Qualification</option>
                                                                            {item.HIGHEST_QUALIFICATION.options && item.HIGHEST_QUALIFICATION.options.map((i) => {
                                                                                return (
                                                                                    <option value={i.QUALIFICATION_ID}> {i.QUALIFICATION_NAME}</option>

                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <select
                                                                            className={"form-select"}
                                                                            value={item.HIGHEST_EDUCATION_QUALIFICATION.value}
                                                                            style={{ width: 'auto' }}

                                                                        >
                                                                            <option value=''> Select Course</option>
                                                                            {item.HIGHEST_EDUCATION_QUALIFICATION.options && item.HIGHEST_EDUCATION_QUALIFICATION.options.map((i) => {
                                                                                return (<option
                                                                                    value={i.EDUCATION_QUALIFICATION_ID}

                                                                                >
                                                                                    {i.COURSE_STREAM}
                                                                                </option>)
                                                                            })}

                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <select
                                                                            className={"form-select"}
                                                                            value={item.COURSE_SPECIALIZATION.value}
                                                                            style={{ width: 'auto' }}

                                                                        >
                                                                            <option value=''> Select Specialization</option>
                                                                            {
                                                                                item.COURSE_SPECIALIZATION.options && item.COURSE_SPECIALIZATION.options.map((i) => {
                                                                                    return (<option
                                                                                        value={i.SPECIALIZATION_ID}
                                                                                    >
                                                                                        {i.SPECIALIZATION}
                                                                                    </option>)
                                                                                })

                                                                            }
                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            className={"form-control"}
                                                                            // value={item.RESUME_FILE_NAME.value}
                                                                            style={{ width: 'auto' }}


                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <select
                                                                            type="text"
                                                                            className="form-select"
                                                                            style={{ width: 'auto' }}

                                                                            value={item.NOTICE_PERIOD.value}
                                                                        >
                                                                            <option value=''> Select Notice Period</option>
                                                                            <option value='Immediate Joiner'> Immediate Joiner</option>
                                                                            <option value='15 Days'> 15 Days</option>
                                                                            <option value='1 Month'> 1 Month</option>
                                                                            <option value='2 Month'> 2 Month</option>
                                                                            <option value='3 Month'> 3 Month or more</option>

                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>
                                                                        <select
                                                                            className={"form-select"}
                                                                            value={item.SOURCE.value}
                                                                            style={{ width: 'auto' }}

                                                                        >
                                                                            <option value=''> Select Lead Source</option>
                                                                            <option value='Found It / Monster'> Found It / Monster</option>
                                                                            <option value='Naukri'> Naukri</option>
                                                                            <option value='LinkedIn'> LinkedIn</option>
                                                                            <option value='Instagram'> Instagram</option>
                                                                            <option value='apna'> apna</option>
                                                                            <option value='Campus'> Campus</option>
                                                                            <option value='Other Portal'> Other Portal</option>
                                                                            <option value='Referral'> Referral</option>
                                                                            <option value='Social Media'> Social Media</option>
                                                                            <option value='Walk-In'> Walk-In</option>
                                                                        </select>
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            style={{ width: 'auto' }}

                                                                            value={item.RATING.value}

                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>
                                                                        <select
                                                                            className={"form-control"}

                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                            value={item.LEAD_OWNER.value}
                                                                            style={{ width: 'auto' }}

                                                                        >
                                                                            <option value=''>
                                                                                Select Lead Owner
                                                                            </option>
                                                                            {item.LEAD_OWNER.options && item.LEAD_OWNER.options.map((item) => {
                                                                                return (
                                                                                    <option key={item.USER_ID} value={item.USER_ID}>
                                                                                        {item.FULL_NAME}
                                                                                    </option>
                                                                                )
                                                                            })}
                                                                        </select>

                                                                    </div></td>


                                                                    <td scope="col"><div>

                                                                        <input

                                                                            type="text"
                                                                            className={"form-control"}
                                                                            value={item.NOTE.value}
                                                                            style={{ width: 'auto' }}


                                                                        />
                                                                    </div></td>
                                                                    <td scope="col"><div>

                                                                        <input
                                                                            type="text"
                                                                            className={"form-control"}
                                                                            value={item.CURRENT_DESIGNATION.value}
                                                                            style={{ width: 'auto' }}


                                                                        />
                                                                    </div></td>
                                                                    <td>
                                                                        <div className="">
                                                                            <button type="submit" className="btn btn-success" onClick={() => { onRemoveRow(index) }}>
                                                                                Remove
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                            )
                                                        })}
                                                    </tbody>


                                                </table>

                                            </div>

                                        </div>
                                    </div> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment >
    )
}

export default Add