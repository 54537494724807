import React, { useEffect, useState } from 'react'
import TeamProgressTable from '../../../../components/companyUser/team/teamInsights/teamProgressTable'
import { v3_team_table_insight } from '../../../../actions/v3/team'
import moment from 'moment';
import queryString from 'query-string';
import styles from './team.module.css'


const Index = (props) => {
  const [tableData, setTableData] = useState(null);
  const [activeTab, setActiveTab] = useState("hiring");
  const [state, setState] = useState(
    {
      FROM_DATE: {
        name: 'FROM_DATE', value: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        isRequired: false
      }, TO_DATE: {
        name: 'TO_DATE', value: moment().format('YYYY-MM-DD'),
        isRequired: false
      },
    }
  )
  useEffect(() => {
    const requestedUrl = props.location.search;
    let { id } = queryString.parse(requestedUrl)
    v3_team_table_insight({ FROM_DATE: state.FROM_DATE.value, TO_DATE: state.TO_DATE.value, USER_ID: id ? id : null }).then((res) => {
      if (res.status) {
        setTableData(res.result)
      }
    })
  }, [state.FROM_DATE.value, state.TO_DATE.value])

  const onChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value
      }
    }));

  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <React.Fragment>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row my-2">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className={`${styles['team-title']}`}>Team insights</h4>
                  <div className="page-title-right">
                    <ol className='ct-breadcrumb breadcrumb m-0'>
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Dashboards</a>
                      </li>
                      <li className="breadcrumb-item active">Team insights</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className='col-md-12'>
                <div className="card rounded-4 card-height-100">
                  <div className="card-header rounded-4 mb-0 border-0 align-items-center d-flex justify-content-between">
                    <div>
                    <h4 className={`${styles['main-title']} card-title mb-0 flex-grow-1`}>Team Progress</h4>
                    <ul className="nav navct-tabs pt-0 border-0 nav-tabs">
                      <li className="nav-item">
                        <button
                          className={`nav-link cp-4 ${activeTab === "home" ? "active" : ""}`}
                          onClick={() => handleTabChange("hiring")}
                        >
                          Hiring Status
                        </button>
                      </li>
                      <li className="nav-item ms-1">
                        <button
                          className={`nav-link cp-4 ${activeTab === "profile" ? "active" : ""}`}
                          onClick={() => handleTabChange("candidate")}
                        >
                          Candidate Status
                        </button>
                      </li>
                    </ul>
                    </div>
                    
                    <div className="flex-shrink-0">
                      <div className="row">

                        <div className="col-xxl-6 col-sm-12 col-12">
                          <label htmlFor="fromDate" className={`${styles['label-ct']}`}> From Date</label>
                          <input
                            type="date"
                            id='fromDate'
                            className="form-control form-control-sm customRadiusSearch"
                            name={state.FROM_DATE.name}
                            value={state.FROM_DATE.value}
                            onChange={onChange}
                          // max={state.TO_DATE.value}
                          />

                        </div>
                        <div className="col-xxl-6 col-sm-12 col-12">
                          <label htmlFor="toDate" className={`${styles['label-ct']}`}> To Date</label>
                          <input
                            type="date"
                            id='toDate'
                            className="form-control form-control-sm customRadiusSearch"
                            name={state.TO_DATE.name}
                            value={state.TO_DATE.value}
                            onChange={onChange}
                          // max={state.FROM_DATE.value}
                          />
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div className="card-body pt-0 px-0">
                    {tableData && <TeamProgressTable
                      data={tableData}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />}

                    {tableData && tableData.length === 0 && <div className="noresult" >
                      <div className="text-center">
                        <lord-icon
                          src="https://cdn.lordicon.com/msoeawqm.json"
                          trigger="loop"
                          colors="primary:#121331,secondary:#08a88a"
                          style={{ width: 75, height: 75 }}
                        />
                        <h5 className="mt-2">Apply Filter to get records as per your requirement</h5>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-12">
                <TeamProgressTable
                  data={tableData}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}

export default Index