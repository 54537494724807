import React from 'react'

const Index = ({ page, parentSection }) => {
    return (
        <div className="row my-2">
            <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">  {page}</h4>
                    <div className="page-title-right">
                        {parentSection}
                        {/* <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item">
                                <a href="javascript: void(0);">Candidate Management</a>
                            </li>
                            <li className="breadcrumb-item active">Add Candidate</li>
                        </ol> */}
                    </div>
                </div>
            </div>
        </div>
        // <div className="row">
        //     <div className="col-12">
        //         <div className="page-title-box d-sm-flex align-items-center justify-content-between">
        //             <div className="col-xl-7 ms-2">
        //                 <h2 className='page-title-dashboard'>
        //                     {page}
        //                 </h2>
        //             </div>
        //             <div className="col-xl-5 ms-2">
        //                 <div className="breadcroumbbox">
        //                     <p>{parentSection}</p>
        //                 </div>

        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Index