const commoncomponent = {
    fourZeroFour: { url: '/404' },
    help: { url: '/help' },
    success: { url: '/success' },

}
const nonAuthedcomponent = {
    landingPage: { url: '/' },
    login: { url: '/login/:user_type' },
    auth_middleware: { url: '/middleware/v1/:parent_id/:url' },
    forgetPassword: { url: '/   -password' },
    requestDemo: { url: '/request-a-demo' },
    requestDemoStatus: { url: '/demo-status/:id' },
    walkInOnboarding: { url: '/onboard/walk-in/:encoded_url' },



}
const superAdminComponent = {
    dashBoard: { url: '/master-dashboard' },
    addClient: { url: '/su/add-client' },
    listClient: { url: '/su/list-client' },
    clientDetail: { url: '/su/detail-client/:id' },
    clientLead: { url: '/su/client-lead' },
    clientSubscriptionInvoice: { url: '/su/client-subscription-invoice/:company_id/:subscription_id' },
    offerLetter: { url: '/su/offer-letter' },
    createOfferLetter: { url: '/su/offer-letter/create' },
    editOfferLetter: { url: '/su/offer-letter/:id' },



    // selectClientPackage: { url: '/su/select-client-:id-package' }




}

const comapnyAdminComponent = {
    dashBoard: { url: '/company-dashboard' },
    addUser: { url: '/company/add-user' },
    teamMapping: { url: '/company/map-team/:user_id' },
    viewUsers: { url: '/company/user' },
    editusers: { url: '/company/edit-dashboard' },
    addClient: { url: '/company/add-client' },
    viewClients: { url: '/company/view-clients' },
    designations: { url: '/settings/designation' },
    workLocation: { url: '/settings/work-location' },
    documentType: { url: '/settings/document-type' },
    role: { url: '/settings/role' },
    profilePreview: { url: '/company-profile' },






}

const companyUserComponent = {
    dashBoard: { url: '/user-dashboard' },
    orgChart: { url: '/org-chart' },
    profilePreview: { url: '/profile' },
    postJob: { url: '/job/post' },
    updateJob: { url: '/job/update/:step/jzacj:job_id' },
    nonPostedJobs: { url: '/job/non-posted' },
    postedJobs: { url: '/job/posted' },
    expiredJobs: { url: '/job/expired' },
    jobApplications: { url: '/job/application/:job_id-:page_number' },
    addCandidate: { url: '/candidate/add' },
    addBulkCandidate: { url: '/candidate/bulk-add' },
    listCandidate: { url: '/candidate/list' },
    messageTemplates: { url: '/candidate/message-templates' },
    candidateProfilePreviewAndApplyJob: { url: '/candidate/profile-apply/:id/:user_name' },
    addUser: { url: '/crm/add-user' },
    editUser: { url: '/crm/:id/edit-user' },
    addClient: { url: '/crm/add-client' },
    viewClients: { url: '/crm/view-clients' },
    clientProfile: { url: '/crm/client/:id/profile' },
    teamMapping: { url: '/crm/map-team/:user_id' },
    viewUsers: { url: '/crm/user' },
    overAllStats: { url: '/crm/overall-stats' },
    teamInsights: { url: '/team-insights' },
    teamStats: { url: '/team-stats' },
    shortlisted: { url: '/shortlisted' },
    interview: { url: '/interview' },
    clientShortlisted: { url: '/client-shortlisted' },
    joined: { url: '/joined' },
    dropped: { url: '/dropped' },
    rejected: { url: '/rejected' },
    offered: { url: '/offered' },
    selected: { url: '/selected' },
    billing: { url: '/billing' },


    company_shortlisted: { url: '/company/shortlisted' },
    company_interview: { url: '/company/interview' },
    company_client_shortlisted: { url: '/company/client-shortlisted' },
    company_joined: { url: '/company/joined' },
    company_dropped: { url: '/company/dropped' },
    company_rejected: { url: '/company/rejected' },
    company_offered: { url: '/company/offered' },
    company_selected: { url: '/company/selected' },
    company_billing: { url: '/company/billing' },



    team_shortlisted: { url: '/team/shortlisted' },
    team_interview: { url: '/team/interview' },
    team_client_shortlisted: { url: '/team/client-shortlisted' },
    team_joined: { url: '/team/joined' },
    team_dropped: { url: '/team/dropped' },
    team_rejected: { url: '/team/rejected' },
    team_offered: { url: '/team/offered' },
    team_selected: { url: '/team/selected' },
    team_billing: { url: '/team/billing' },


    JobApplicationReport: { url: '/job-application-report' },
    CandidateHiringStatus: { url: '/candidate/hiring-status' },
    QrWalkInCandidates: { url: '/candidate/qr-walk-in' },


    callingDirectory:{url:'/candidate/calling-directory'}


}


const keys = {
    superAdminToken: 'r_jsh_satk.crm.jobzshala.com',
    superAdminDetails: 'r_jsh_saud.crm.jobzshala.com',

    companyAdminToken: 'r_jsh_catk.crm.jobzshala.com',
    companyAdminDetails: 'r_jsh_caud.crm.jobzshala.com',


    companyUserToken: 'r_jsh_cutk.crm.jobzshala.com',
    companyUserDetails: 'r_jsh_cuud.crm.jobzshala.com',
    companyUserHavingTeam: 'r_jsh_cuht.crm.jobzshala.com',
    companyUserHavingAdminRights: 'r_jsh_cuhar.crm.jobzshala.com',
    companyUserSocketId: 'r_jsh_cusi.crm.jobzshala.com',


    walkInRegistration: 'r_jsh_wir.crm.jobzshala.com',
    walkInRegistrationCompleted: 'r_jsh_wirc.crm.jobzshala.com',








}
export default { keys, nonAuthedcomponent, commoncomponent, superAdminComponent, comapnyAdminComponent, companyUserComponent }