import React, { useEffect, useState } from 'react'
import SearchClient from '../../../../components/companyUser/administrator/clientManagement/List'
import { v3_search_client } from '../../../../actions/v3/administrator';

const List = () => {

    const [list, setList] = useState(null);
    const [count, setCount] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const [searchQuery, setSearchQuery] = useState({
        CLIENT_NAME: '',
        EMAIL: '',
        PHONE: '',
    });


    useEffect(() => {
        v3_search_client(btoa(JSON.stringify({ PAGE: currentPage, ...searchQuery }))).then((res) => {
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count)
            }
        })
    }, [currentPage, searchQuery]);


    return (
        <React.Fragment>

            <SearchClient
                setSearchQuery={setSearchQuery}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                list={list}
                count={count}
            />
        </React.Fragment>
    )
}

export default List