import React from 'react'
import constant from '../../../../constant';
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './dashboard.module.css';
export const options = {
  hAxis: {
    title: "Month",
  },
  series: {
    1: { curveType: "function" },
  },
};



const Stats = (props) => {
  const { countStats } = props;
  return (
    <React.Fragment>
        <div className="row">
          <div className="col-md-4 ">
            <div className={`${styles.cardBox} card card-animate overflow-hidden customCardGiridBox`}>

              <div className="card-body" style={{ zIndex: 1 }} onClick={() => { props.history.push(constant.companyUserComponent.listCandidate.url) }}>
                <div className="row">

                  <div className={`${styles.pdr} col-md-7`}>

                    <p className={styles.gridTitleStyle}>
                      {" "}
                      Total Candidates
                    </p>
                    <h4 className={styles.gridCountStyle}>
                      <span className="counter-value" >
                        {countStats?.total_candidates ? countStats?.total_candidates : 0}
                      </span>
                    </h4>
                  </div>
                  <div className="col-md-5">
                    <div className={`${styles.setsize} ${styles.chartscontainer}`}>
                      <div className={`${styles.piewrapper} ${styles.progress30}`}>
                        <span className={styles.label}>30<span className={styles.smaller}>%</span></span>
                        <div className={styles.pie}>
                          <div className={`${styles.leftside} ${styles.halfcircle}`}></div>
                          <div className={`${styles.rightside} ${styles.halfcircle}`}></div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 " onClick={() => { props.history.push(constant.companyUserComponent.jobManagement.url) }}>
            <div className={`${styles.cardBox} card card-animate overflow-hidden customCardGiridBox`}>

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-7">
                    <p className={styles.gridTitleStyle}>
                      {" "}
                      Total Jobs
                    </p>
                    <h4 className={styles.gridCountStyle}>
                      <span className="counter-value" >
                        {countStats?.total_jobs ? countStats?.total_jobs : 0}
                      </span>
                    </h4>

                  </div>
                  <div className="col-md-5">
                    <div className={`${styles.setsize} ${styles.chartscontainer}`}>
                      <div className={`${styles.piewrapper} ${styles.progress45}`}>
                        <span className={styles.label}>48<span className={styles.smaller}>%</span></span>
                        <div className={styles.pie}>
                          <div className={`${styles.leftside} ${styles.halfcircle}`}></div>
                          <div className={`${styles.rightside} ${styles.halfcircle}`}></div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 "
            onClick={() => { props.history.push(constant.companyUserComponent.company_billing.url) }}
          >
            <div className={`${styles.cardBox} card card-animate overflow-hidden customCardGiridBox`}>
              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-7">
                    <p className={styles.gridTitleStyle}>
                      Billing Cycle
                    </p>
                    <h4 className={styles.gridCountStyle}>
                      <span className="counter-value">
                        {countStats?.billing ? countStats?.billing : 0}
                      </span>
                    </h4>

                  </div>
                  <div className="col-md-5">
                    <div className={`${styles.setsize} ${styles.chartscontainer}`}>
                      <div className={`${styles.piewrapper} ${styles.progress75}`}>
                        <span className={styles.label}>67<span className={styles.smaller}>%</span></span>
                        <div className={styles.pie}>
                          <div className={`${styles.leftside} ${styles.halfcircle}`}></div>
                          <div className={`${styles.rightside} ${styles.halfcircle}`}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
       
      

    </React.Fragment>
  )
}

export default withRouter(Stats)