import React, { useEffect, useState } from 'react'
import { v1_course_specialization, v1_education_qualification, v1_employment_type, v1_functional_area, v1_industry, v1_job_role, v1_qualification } from '../../../../actions/v1/master_data';
import { validateFunctionalForm } from '../../../../utils';
import Select from 'react-select';
import styles from './step.module.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrochip } from '@fortawesome/free-solid-svg-icons';
import { v4_job_description_generator } from '../../../../actions/v4/jdGenerator';
import { toast } from 'react-toastify';


const StepThree = (props) => {

    const [state, setState] = useState({
        IS_WORK_FROM_HOME_JOB: { name: 'IS_WORK_FROM_HOME_JOB', value: props.formData ? props.formData.IS_WORK_FROM_HOME_JOB : '', error: '', isRequired: true },
        WORK_FROM_TYPE: { name: 'WORK_FROM_TYPE', value: props.formData ? props.formData.WORK_FROM_TYPE : '', error: '', isRequired: false },
        OFFICE_LOCATION: { name: 'OFFICE_LOCATION', value: props.formData ? props.formData.OFFICE_LOCATION : '', error: '', isRequired: false },
        SKILLS: { name: 'SKILLS', value: props.formData ? props.formData.SKILLS : '', error: '', isRequired: true },

        // INDUSTRY: { name: 'INDUSTRY', value: props.formData ? props.formData.INDUSTRY : '', options: [], error: '', isRequired: true },
        // FUNCTIONAL_AREA: { name: 'FUNCTIONAL_AREA', value: props.formData ? props.formData.FUNCTIONAL_AREA : '', options: [], error: '', isRequired: true },
        // ROLE: { name: 'ROLE', value: props.formData ? props.formData.ROLE : '', options: [], error: '', isRequired: true },

        // NO_OF_VACANCY: { name: 'NO_OF_VACANCY', value: props.formData ? props.formData.NO_OF_VACANCY : '', options: [], error: '', isRequired: true },
        QUALIFICATION: { name: 'QUALIFICATION', value: props.formData ? props.formData.QUALIFICATION : '', options: [], error: '', isRequired: true },
        EDUCATION_QUALIFICATION: { name: 'EDUCATION_QUALIFICATION', value: props.formData ? props.formData.EDUCATION_QUALIFICATION : '', options: [], error: '', isRequired: true },
        COURSE_SPECIALIZATION: { name: 'COURSE_SPECIALIZATION', value: props.formData ? props.formData.COURSE_SPECIALIZATION : '', options: [], error: '', isRequired: false },
        DATE_OF_EXPIRY: { name: 'DATE_OF_EXPIRY', value: props.formData ? props.formData.DATE_OF_EXPIRY : '', options: [], error: '', isRequired: false },
        JOB_DETAILS: { name: 'JOB_DETAILS', value: props.formData ? props.formData.JOB_DETAILS : '', error: '', isRequired: true },


    })
    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }


    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }

    const onSubmit = () => {
        if (validateFunctionalForm(state, setState)) {
            const model = {
                IS_WORK_FROM_HOME_JOB: state.IS_WORK_FROM_HOME_JOB.value,
                WORK_FROM_TYPE: state.WORK_FROM_TYPE.value,
                OFFICE_LOCATION: state.OFFICE_LOCATION.value,
                SKILLS: state.SKILLS.value,
                // INDUSTRY: state.INDUSTRY.value,
                // FUNCTIONAL_AREA: state.FUNCTIONAL_AREA.value,
                // ROLE: state.ROLE.value,
                // NO_OF_VACANCY: state.NO_OF_VACANCY.value,
                QUALIFICATION: state.QUALIFICATION.value,
                EDUCATION_QUALIFICATION: state.EDUCATION_QUALIFICATION.value,
                COURSE_SPECIALIZATION: state.COURSE_SPECIALIZATION.value,
                DATE_OF_EXPIRY: state.DATE_OF_EXPIRY.value,
                JOB_DETAILS: state.JOB_DETAILS.value,

            };
            props.onSave(model)
        }
    }

    useState(() => {
        // v1_industry().then((res) => {
        //     if (res.status) {
        //         setOptions(state.INDUSTRY.name, res.result)
        //     }
        //     else {
        //         console.error(res.error);
        //     }
        // })

        // v1_functional_area().then((res) => {
        //     if (res.status) {
        //         setOptions(state.FUNCTIONAL_AREA.name, res.result)
        //     }
        //     else {
        //         console.error(res.error);
        //     }
        // })

        v1_qualification().then((res) => {
            if (res.status) {
                setOptions(state.QUALIFICATION.name, res.result.map((item) => {
                    return {
                        value: item.QUALIFICATION_ID,
                        label: item.QUALIFICATION_NAME
                    }
                }))
            }
            else {
                console.error(res.error);
            }
        })

    }, [])
    // useEffect(() => {
    //     if (state.FUNCTIONAL_AREA.value) {
    //         v1_job_role(state.FUNCTIONAL_AREA.value).then((res) => {
    //             if (res.status) {
    //                 setOptions(state.ROLE.name, res.result)
    //             }
    //             else {
    //                 console.error(res.error);
    //             }
    //         })
    //     }

    // }, [state.FUNCTIONAL_AREA.value])


    useEffect(() => {
        if (state.QUALIFICATION.value?.length > 0) {
            v1_education_qualification(state.QUALIFICATION.value.map((item) => {
                return item.value
            }).toString()).then((res) => {
                if (res.status) {
                    setOptions(state.EDUCATION_QUALIFICATION.name, res.result.map((item) => {
                        return {
                            value: item.EDUCATION_QUALIFICATION_ID,
                            label: item.COURSE_STREAM
                        }
                    }))
                }
                else {
                    console.error(res.error);
                }
            })
        }

    }, [state.QUALIFICATION.value])


    useEffect(() => {
        if (state.EDUCATION_QUALIFICATION.value?.length > 0) {
            v1_course_specialization(state.EDUCATION_QUALIFICATION.value.map((item) => {
                return item.value
            }).toString()).then((res) => {
                if (res.status) {
                    setOptions(state.COURSE_SPECIALIZATION.name, res.result.map((item) => {
                        return {
                            value: item.SPECIALIZATION_ID,
                            label: item.SPECIALIZATION
                        }
                    }))
                }
                else {
                    console.error(res.error);
                }
            })
        }

    }, [state.EDUCATION_QUALIFICATION.value])

    useEffect(() => {
        if (state.QUALIFICATION.value?.length > 0) {
            v1_education_qualification(state.QUALIFICATION.value.map((item) => {
                return item.value
            }).toString()).then((res) => {
                if (res.status) {
                    setOptions(state.EDUCATION_QUALIFICATION.name,
                        res.result.map((item) => {
                            return {
                                value: item.EDUCATION_QUALIFICATION_ID,
                                label: item.COURSE_STREAM
                            }
                        })
                    )
                }
                else {
                    console.error(res.error);
                }
            })
        }

    }, [state.QUALIFICATION.value])


    useEffect(() => {
        if (state.IS_WORK_FROM_HOME_JOB.value === 'Y') {
            setState(prevState => ({
                ...prevState,
                ['WORK_FROM_TYPE']: {
                    ...prevState['WORK_FROM_TYPE'],
                    isRequired: true,
                }
            }));
        }
        else {
            setState(prevState => ({
                ...prevState,
                ['WORK_FROM_TYPE']: {
                    ...prevState['WORK_FROM_TYPE'],
                    isRequired: false,
                    value: ''
                }
            }));
        }

    }, [state.IS_WORK_FROM_HOME_JOB.value])
    console.log(state.EDUCATION_QUALIFICATION.options)

    return (
        <React.Fragment>
            <div className="tab-content">
                <div
                    className="tab-pane fade active show"
                    id="pills-gen-info"
                    role="tabpanel"
                    aria-labelledby="pills-gen-info-tab"
                >
                    <div>
                        <div className="mb-4">
                            <div className={styles.pageDetailsBox}>
                                <h5 className="mb-1">Other Details</h5>
                                <p className="text-muted">Fill all Information as below</p>
                            </div>
                        </div>
                        <div className={styles.formsSectionBox}>
                            <div className={` ${styles.postNewJob} row`}>
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="mb-3">
                                                <label
                                                    className="form-label"
                                                >
                                                    Is this Work from home Job ?
                                                    <span className='text-danger'>&nbsp;*</span>

                                                </label>
                                                <select
                                                    className={state.IS_WORK_FROM_HOME_JOB.error && !state.IS_WORK_FROM_HOME_JOB.value ? "form-select is-invalid" : "form-select"}
                                                    name={state.IS_WORK_FROM_HOME_JOB.name}
                                                    value={state.IS_WORK_FROM_HOME_JOB.value}
                                                    onChange={onChange}
                                                >
                                                    <option value={''}>Select Job Type</option>
                                                    <option value={'Y'}>Yes</option>
                                                    <option value={'N'}>No</option>

                                                </select>
                                                <div className="invalid-feedback">
                                                    Please enter a job title
                                                </div>
                                            </div>


                                        </div>
                                        {state.IS_WORK_FROM_HOME_JOB.value === 'Y' && <React.Fragment>
                                            <div className="col-lg-3">
                                                <div className="mb-3">
                                                    <label
                                                        className="form-label"
                                                    >
                                                        Permanent /  Flexible  ?
                                                        <span className='text-danger'>&nbsp;*</span>

                                                    </label>
                                                    <select
                                                        className={state.WORK_FROM_TYPE.error && !state.WORK_FROM_TYPE.value ? "form-select is-invalid" : "form-select"}
                                                        name={state.WORK_FROM_TYPE.name}
                                                        value={state.WORK_FROM_TYPE.value}
                                                        onChange={onChange}
                                                    >
                                                        <option value=''>Select Work From Home Type</option>
                                                        <option value='PRJ'>Permanemt Remote Job</option>
                                                        <option value='TRJ'>Flexible</option>


                                                    </select>
                                                    <div className="invalid-feedback">
                                                        Please enter a job title
                                                    </div>
                                                </div>


                                            </div>
                                        </React.Fragment>}
                                        {(state.IS_WORK_FROM_HOME_JOB.value === 'N' || state.WORK_FROM_TYPE.value === 'TRJ') && <React.Fragment>
                                            <div className={state.IS_WORK_FROM_HOME_JOB.value === 'N' ? "col-lg-9" : state.WORK_FROM_TYPE.value === 'TRJ' ? "col-lg-6" : 'col-lg-3'}>
                                                <div className="mb-3">
                                                    <label
                                                        className="form-label"
                                                    >
                                                        Office Location
                                                        <span className='text-danger'>&nbsp;*</span>

                                                    </label>
                                                    <input
                                                        className={state.OFFICE_LOCATION.error && !state.OFFICE_LOCATION.value ? "form-control is-invalid" : "form-control"}
                                                        placeholder='Office Location'
                                                        name={state.OFFICE_LOCATION.name}
                                                        value={state.OFFICE_LOCATION.value}
                                                        onChange={onChange}
                                                    />

                                                    <div className="invalid-feedback">
                                                        Please enter office location
                                                    </div>
                                                </div>


                                            </div>
                                        </React.Fragment>}
                                    </div>

                                </div>

                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label className="form-label">Skills and Experience
                                            <span className='text-danger'>&nbsp;*</span>

                                        </label>
                                        <input
                                            type='text'
                                            className={state.SKILLS.error && !state.SKILLS.value ? "form-control is-invalid" : "form-control"}
                                            placeholder='Enter Required Skills Comma Separated ( i.e, Node.js, Javascript... )'
                                            name='SKILLS'
                                            value={state.SKILLS.value}
                                            onChange={onChange}
                                        />
                                        <div className="invalid-feedback">
                                            Please enter work experience
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label">Industry
                                            <span className='text-danger'>&nbsp;*</span>

                                        </label>
                                        <select
                                            className={state.INDUSTRY.error && !state.INDUSTRY.value ? "form-select is-invalid" : "form-select"}
                                            name='INDUSTRY'
                                            value={state.INDUSTRY.value}
                                            onChange={onChange}
                                        >
                                            <option value=''> Select Industry</option>
                                            {
                                                state.INDUSTRY.options.map((item) => {
                                                    return (
                                                        <option value={item.INDUSTRY_ID}>{item.INDUSTRY}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <div className="invalid-feedback">
                                            Please select industry
                                        </div>
                                    </div>
                                </div>




                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label">Functional Area
                                            <span className='text-danger'>&nbsp;*</span>

                                        </label>
                                        <select
                                            className={state.FUNCTIONAL_AREA.error && !state.FUNCTIONAL_AREA.value ? "form-select is-invalid" : "form-select"}
                                            name='FUNCTIONAL_AREA'
                                            value={state.FUNCTIONAL_AREA.value}
                                            onChange={onChange}
                                        >
                                            <option value=''> Select Functional Area
                                                <span className='text-danger'>&nbsp;*</span>

                                            </option>
                                            {
                                                state.FUNCTIONAL_AREA.options.map((item) => {
                                                    return (
                                                        <option value={item.FUNCTIONAL_AREA_ID}>{item.FUNCTIONAL_AREA}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <div className="invalid-feedback">
                                            Please select functional area
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label">Role
                                            <span className='text-danger'>&nbsp;*</span>

                                        </label>
                                        <select
                                            className={state.ROLE.error && !state.ROLE.value ? "form-select is-invalid" : "form-select"}
                                            name='ROLE'
                                            value={state.ROLE.value}
                                            onChange={onChange}
                                        >
                                            <option value=''> Select Role</option>
                                            {
                                                state.ROLE.options && state.ROLE.options.map((item) => {
                                                    return (
                                                        <option value={item.ROLE_ID}>{item.ROLE_NAME}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <div className="invalid-feedback">
                                            Please select job role
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label">No. of vacancies
                                            <span className='text-danger'>&nbsp;*</span>

                                        </label>
                                        <input
                                            type='number'
                                            min="0"
                                            className={state.NO_OF_VACANCY.error && !state.NO_OF_VACANCY.value ? "form-control is-invalid" : "form-control"}
                                            placeholder='No. of vacancies'
                                            name={state.NO_OF_VACANCY.name}
                                            value={state.NO_OF_VACANCY.value}
                                            onChange={onChange}
                                        />
                                        <div className="invalid-feedback">
                                            Please enter no. of vacancies
                                        </div>
                                    </div>
                                </div> */}

                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label">Qualification
                                            <span className='text-danger'>&nbsp;*</span>

                                        </label>
                                        <Select
                                            // defaultValue={[colourOptions[2], colourOptions[3]]}

                                            isMulti
                                            name="QUALIFICATION"
                                            options={state.QUALIFICATION.options}
                                            value={state.QUALIFICATION.value}
                                            onChange={(e) => {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    ['QUALIFICATION']: {
                                                        ...prevState['QUALIFICATION'],
                                                        value: e
                                                    }
                                                }));
                                            }}
                                            className={`${styles.qualifiSection} basic-multi-select`}
                                            classNamePrefix="select"
                                        />
                                        <div className="invalid-feedback">
                                            Please select Qualification
                                        </div>
                                    </div>
                                </div>



                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label">Education Qualification
                                            <span className='text-danger'>&nbsp;*</span>

                                        </label>
                                        <Select
                                            // defaultValue={[colourOptions[2], colourOptions[3]]}
                                            isMulti
                                            name="EDUCATION_QUALIFICATION"
                                            options={state.EDUCATION_QUALIFICATION.options}
                                            value={state.EDUCATION_QUALIFICATION.value}
                                            onChange={(e) => {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    ['EDUCATION_QUALIFICATION']: {
                                                        ...prevState['EDUCATION_QUALIFICATION'],
                                                        value: e
                                                    }
                                                }));
                                            }}
                                            className={`${styles.qualifiSection} basic-multi-select`}
                                            classNamePrefix="select"
                                        />
                                        <div className="invalid-feedback">
                                            Please select Qualification
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label">Specialization

                                        </label>
                                        <Select
                                            // defaultValue={[colourOptions[2], colourOptions[3]]}
                                            isMulti
                                            name="COURSE_SPECIALIZATION"
                                            options={state.COURSE_SPECIALIZATION.options}
                                            value={state.COURSE_SPECIALIZATION.value}
                                            onChange={(e) => {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    ['COURSE_SPECIALIZATION']: {
                                                        ...prevState['COURSE_SPECIALIZATION'],
                                                        value: e
                                                    }
                                                }));
                                            }}
                                            className={`${styles.qualifiSection} basic-multi-select`}
                                            classNamePrefix="select"
                                        />
                                        <div className="invalid-feedback">
                                            Please select Specialization
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label
                                            className="form-label"
                                        > Job Detail

                                            <span className='text-danger'>&nbsp;*</span>

                                        </label>
                                        <ReactQuill
                                            theme="snow"
                                            value={state.JOB_DETAILS.value}
                                            onChange={(value) => {
                                                setState({
                                                    ...state,
                                                    JOB_DETAILS: {
                                                        ...state.JOB_DETAILS,
                                                        value: value,
                                                        error: !value.trim(),
                                                    }
                                                });
                                            }}
                                            placeholder="Enter Job Details"
                                            modules={{
                                                toolbar: [
                                                    [{ 'header': [1, 2, false] }],
                                                    ['bold', 'italic', 'underline', 'strike'],
                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                    ['link', 'image'],
                                                    ['clean']
                                                ],
                                            }}
                                            className={state.JOB_DETAILS.error && !state.JOB_DETAILS.value ? "is-invalid" : ""}

                                        />
                                        {state.JOB_DETAILS.error && !state.JOB_DETAILS.value && (
                                            <div className="invalid-feedback">Job details are required.</div>
                                        )}

                                    </div>

                                </div>
                                <div className="col-lg-12">
                                    <button
                                        type="button"
                                        className={`${styles.AiJDbtn} btn  btn-label left text-start`}
                                        onClick={() => {
                                            props.setShowLoader(true);
                                            debugger;

                                            // Validation function
                                            const validateModel = () => {
                                                // Error messages array to store validation issues
                                                const errors = [];

                                                // Check for null or invalid values and push errors
                                                if (!state.IS_WORK_FROM_HOME_JOB.value) {
                                                    errors.push("Work From Home Job status is required.");
                                                }

                                                if (state.IS_WORK_FROM_HOME_JOB.value !== 'Y' && !state.OFFICE_LOCATION.value) {
                                                    errors.push("Office location is required for non-remote jobs.");
                                                }

                                                if (!state.EDUCATION_QUALIFICATION.value || state.EDUCATION_QUALIFICATION.value.length === 0) {
                                                    errors.push("At least one educational qualification is required.");
                                                }

                                                // Course specialization is optional, but we still need to check if it’s empty if provided
                                                if (state.COURSE_SPECIALIZATION.value && state.COURSE_SPECIALIZATION.value.length === 0) {
                                                    errors.push("At least one course specialization is required.");
                                                }

                                                // If there are errors, show an alert and stop further processing
                                                if (errors.length > 0) {
                                                    toast.error(`Please fix the following errors:\n\n${errors.join('\n')}`);
                                                    return false; // Indicate validation failed
                                                }

                                                return true; // Validation passed
                                            };

                                            // Model creation function
                                            const createModel = () => {
                                                // Validate before creating the model
                                                if (!validateModel()) return null; // Return null if validation fails

                                                // Proceed to create the model if validation passes
                                                const model = {
                                                    ...props.aiJDModel,
                                                    Location: state.IS_WORK_FROM_HOME_JOB.value === 'Y'
                                                        ? state.WORK_FROM_TYPE.value === 'PRJ'
                                                            ? 'Remote'
                                                            : 'Flexible With Office Location ' + state.OFFICE_LOCATION.value
                                                        : state.OFFICE_LOCATION.value,
                                                    Degree: state.EDUCATION_QUALIFICATION.value
                                                        .map((course) => course.label)
                                                        .join(', '),
                                                    Specialization: state.COURSE_SPECIALIZATION.value.length > 0
                                                        ? state.COURSE_SPECIALIZATION.value
                                                            .map((specialization) => specialization.label)
                                                            .join(', ')
                                                        : 'Not Mentioned' // Default if specialization is empty
                                                };

                                                return model;
                                            };

                                            // Create the model
                                            const model = createModel();
                                            if (!model) {
                                                props.setShowLoader(false); // Hide the loader if model creation fails
                                                return; // Stop execution if the model creation failed
                                            }

                                            // Call the API or function to generate the job description
                                            v4_job_description_generator(model).then((res) => {
                                                props.setShowLoader(false);
                                                if (res) {
                                                    setState({
                                                        ...state,
                                                        JOB_DETAILS: {
                                                            ...state.JOB_DETAILS,
                                                            value: res,
                                                        }
                                                    });
                                                } else {
                                                    toast.error(res.error);
                                                }
                                            }).catch((error) => {
                                                props.setShowLoader(false);
                                                toast.error("An error occurred while generating the job description.");
                                            });
                                        }}


                                    >
                                        <FontAwesomeIcon icon={faMicrochip} className="fa-fade fs-16 mr-5" />

                                        Generate With AI
                                    </button>


                                </div>


                                {/* <div className="col-lg-6">
                                <div className="mb-3">
                                    <label className="form-label">Date of Expiry
                                        <span className='text-danger'>&nbsp;*</span>

                                    </label>
                                    <input
                                        type='date'
                                        className={state.DATE_OF_EXPIRY.error && !state.DATE_OF_EXPIRY.value ? "form-control is-invalid" : "form-control"}
                                        name='DATE_OF_EXPIRY'
                                        value={state.DATE_OF_EXPIRY.value}
                                        onChange={onChange}
                                    />
                                    <div className="invalid-feedback">
                                        Please select Specialization
                                    </div>
                                </div>
                            </div> */}
                            </div>
                        </div>

                    </div>
                    <div className="d-flex align-items-start justify-content-end gap-3 mt-4">
                        <button
                            type="button"
                            onClick={() => { props.onPrevious() }}
                            className={`${styles.cancelBtn} btn btn-primary btn-label left`}
                        >
                            {/* <i className="ri-arrow-left-line label-icon align-middle fs-16" /> */}

                            Previous
                        </button>

                        <button
                            type="button"
                            className={` ${styles.buttonNext} btn  nexttab nexttab`}
                            onClick={() => { onSubmit() }}
                        >
                            {/* <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2" /> */}
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default StepThree