import React, { useState } from 'react'
import DrawerModal from '../../../../common/htmlDrawerModal'
import ClientDetailDrawerModal from '../clientDetailDrawerModal'
import moment from 'moment';
import Pagination from '../../../../common/Pagination';
import Modal from '../../../../common/htmlModal';
import Edit from '../editModal';
import { ToastContainer, toast } from 'react-toastify';
import { v3_edit_client } from '../../../../../actions/v3/administrator';
import constant from '../../../../../constant';
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import Breadcrumb from '../../../../common/BreadCrumb';
import styles from './style.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPhone, faTableList, faUserEdit } from '@fortawesome/free-solid-svg-icons';
const List = (props) => {
    const { list, count, currentPage } = props;

    const [showClientDetailDrawerModal, setShowClientDetailDrawerModal] = useState(false)
    const [clientDetails, setClientDetails] = useState(null)
    const [showEditModal, setShowEditModal] = useState(null)


    const [searchState, setSearchState] = useState({
        CLIENT_NAME: { name: 'CLIENT_NAME', value: '', error: '', isRequired: false },
        EMAIL: { name: 'EMAIL', value: '', visible: false, error: '', isRequired: false },
        PHONE: { name: 'PHONE', value: '', visible: false, error: '', isRequired: false },


    })
    const onReset = () => {
        setSearchState({
            CLIENT_NAME: { name: 'CLIENT_NAME', value: '', error: '', isRequired: false },
            EMAIL: { name: 'EMAIL', value: '', visible: false, error: '', isRequired: false },
            PHONE: { name: 'PHONE', value: '', visible: false, error: '', isRequired: false },
        })
        const model = {
            CLIENT_NAME: '',
            EMAIL: '',
            PHONE: '',
        }

        props.setSearchQuery(model)
    }
    const onSearch = () => {
        const model = {
            CLIENT_NAME: searchState.CLIENT_NAME.value,
            EMAIL: searchState.EMAIL.value,
            PHONE: searchState.PHONE.value,
        }

        props.setSearchQuery(model)
    }
    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));

    }
    const onSubmit = (model) => {
        v3_edit_client(model).then((res) => {
            if (res.status) {
                toast.success('Client Added Successfully')
                setShowEditModal(null)
            }
            else {
                toast.error(res.error)

            }
        }).catch((err) => {
            toast.error(err)

        })
    }
    const colors = ['#32BBF9', '#FECC00'];
    return (
        <React.Fragment>
            <Modal isOpen={showEditModal}
                header='Edit Client Details'
            >
                <Edit
                    data={showEditModal}
                    onClose={() => { setShowEditModal(null) }}
                    onSubmit={onSubmit}
                />

            </Modal>
            <ToastContainer />
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-md-12'>
                        <Modal
                            isOpen={showClientDetailDrawerModal}
                            onClose={() => { setShowClientDetailDrawerModal(false) }}
                        >
                            {clientDetails && <ClientDetailDrawerModal
                                clientDetails={clientDetails}
                            />}
                        </Modal>
                        <Breadcrumb
                            page={'Search Client'}
                            parentSection={<ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript: void(0);">Client Management</a>
                                </li>
                                <li className="breadcrumb-item active">Client Search</li>
                            </ol>}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-12'>
                        <div className={`${`${styles['filter-box']}`}`}>
                            <div className="search-box w-25">
                                <input
                                    type="text"
                                    className="form-control search"
                                    placeholder="Company Name, SPOC Name"
                                    name={searchState.CLIENT_NAME.name}
                                    value={searchState.CLIENT_NAME.value}
                                    onChange={onChange}
                                />
                                <i className="ri-search-line search-icon" />
                            </div>
                            <div className="search-box w-25">
                                <input
                                    type="text"
                                    className="form-control search"
                                    placeholder="Email"
                                    name={searchState.EMAIL.name}
                                    value={searchState.EMAIL.value}
                                    onChange={onChange}
                                />
                                <i className="ri-mail-line search-icon" />
                            </div>
                            <div className="search-box w-25">
                                <input
                                    type="number"
                                    min={5}
                                    className="form-control search"
                                    placeholder="Phone"
                                    name={searchState.PHONE.name}
                                    value={searchState.PHONE.value}
                                    onChange={onChange}
                                />
                                <i className="ri-phone-line search-icon" />
                            </div>
                            <div className="hstack gap">
                                <button type="button" onClick={() => { onReset() }} className={`${styles['reset-btn']} btn`}>
                                    Reset
                                </button>
                                <button type="button" onClick={() => {
                                    onSearch()
                                }} className={`${styles['search-btn']} btn ms-1`}>
                                    <i className="ri-search-line search-icon align-bottom me-1" />
                                    Search
                                </button>
                            </div>
                            <div className="hstack gap">
                                <button type="button" onClick={() => {
                                    props.history.push(constant.companyUserComponent.addClient.url)
                                }} className={`${styles['addnew-btn']} btn ms-1`}>
                                    <i className="ri-add-line align-bottom me-1" />
                                    Add New
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className={styles.ourClientsSecton}>
                            <div className={`${styles.applicationTitle} ${styles.mrb20}`}>
                                <h4>Clients</h4>
                            </div>

                            <div className='row'>
                                {list && list.map((item) => {
                                    const isExpired = item.AGREEMENT_END_DATE
                                        ? moment(item.AGREEMENT_END_DATE).isBefore(moment(), 'day')
                                        : false;
                                    return (
                                        <div className='col-md-6'>
                                            <div className={`${styles.clientCardBox} ${isExpired ? styles.secondBoxBg : ''} position-relative`}>
                                                <h4 className='text-truncate' title={item.COMPANY_NAME}> {item.COMPANY_NAME}</h4>
                                                <ul className={styles.clientInfoBox}>
                                                    <li>
                                                        <span className={`${styles.spanPerson}`}>Contact Person:</span>
                                                        <span className={styles.spanName}>{item.CLIENT_NAME}</span>
                                                    </li>


                                                    <li>
                                                        <span className={styles.spanPerson}>GST No.:</span>
                                                        <span className={styles.spanName}>{item.GST_NO ? item.GST_NO : 'Not Available'}</span>
                                                    </li>

                                                    <li>
                                                        <span className={styles.spanPerson}>Mobile :</span>
                                                        <span className={styles.spanName}>{item.PHONE ? item.PHONE : 'Not Available'}</span>
                                                    </li>
                                                    <li>
                                                        <span className={styles.spanPerson}>Email :</span>
                                                        <span className={styles.spanName}>{item.EMAIL ? item.EMAIL : 'Not Available'}</span>
                                                    </li>
                                                    <li>
                                                        <span className={styles.spanPerson}>Agreement Start:</span>
                                                        <span className={styles.spanName}>{item.AGREEMENT_START_DATE ? moment(item.AGREEMENT_START_DATE).format('DD-MM-YYYY') : 'Not Available'}</span>
                                                    </li>
                                                    <li>
                                                        <span className={styles.spanPerson}>Agreement End:</span>
                                                        <span className={styles.spanName}>{item.AGREEMENT_END_DATE ? moment(item.AGREEMENT_END_DATE).format('DD-MM-YYYY') : 'Not Available'}</span>
                                                    </li>
                                                </ul>
                                                <div>
                                                    <ul className={`${styles['details-list']}`}>
                                                        <li>
                                                            <a
                                                                href="javascript:void(0)"
                                                                onClick={() => setShowEditModal(item)}
                                                                title="Edit Client"
                                                            >
                                                                <FontAwesomeIcon icon={faUserEdit} />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="javascript:void(0)"
                                                                onClick={() => {
                                                                    setClientDetails(item);
                                                                    setShowClientDetailDrawerModal(true);
                                                                }}
                                                                title="View Details"
                                                            >
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href={`tel:${item.PHONE}`} title="Call Client">
                                                                <FontAwesomeIcon icon={faPhone} />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                href={constant.companyUserComponent.clientProfile.url.replace(
                                                                    ":id",
                                                                    item.CLIENT_ID
                                                                )}
                                                                title="View Profile"
                                                            >
                                                                <FontAwesomeIcon icon={faTableList} />
                                                            </a>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }


                            </div>

                        </div>

                    </div>
                    {/* <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header border-0">
                                <div className="row g-4 align-items-center">
                                    <div className="col-sm-3">
                                        <div className="search-box">
                                            <input
                                                type="text"
                                                className="form-control search"
                                                placeholder="Company Name, SPOC Name"
                                                name={searchState.CLIENT_NAME.name}
                                                value={searchState.CLIENT_NAME.value}
                                                onChange={onChange}
                                            />
                                            <i className="ri-search-line search-icon" />
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="search-box">
                                            <input
                                                type="text"
                                                className="form-control search"
                                                placeholder="Email"
                                                name={searchState.EMAIL.name}
                                                value={searchState.EMAIL.value}
                                                onChange={onChange}
                                            />
                                            <i className="ri-mail-line search-icon" />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="search-box">
                                            <input
                                                type="number"
                                                min={5}
                                                className="form-control search"
                                                placeholder="Phone"
                                                name={searchState.PHONE.name}
                                                value={searchState.PHONE.value}
                                                onChange={onChange}
                                            />
                                            <i className="ri-phone-line search-icon" />
                                        </div>
                                    </div>
                                    <div className="col-sm-auto">
                                        <div className="hstack gap">
                                            <button type="button" onClick={() => { onReset() }} className="btn btn-danger add-btn">
                                                Reset
                                            </button>
                                            <button type="button" onClick={() => {
                                                onSearch()
                                            }} className="btn btn-success add-btn ms-1">
                                                <i className="ri-search-line search-icon align-bottom me-1" />
                                                Search
                                            </button>

                                        </div>
                                    </div>
                                    <div className="col-sm-auto justify-content-end">
                                        <div className="hstack gap">
                                            <button type="button" onClick={() => {
                                                props.history.push(constant.companyUserComponent.addClient.url)
                                            }} className="btn btn-primary add-btn ms-1">
                                                <i className="ri-add-line align-bottom me-1" />
                                                Add New
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <div class="col-md-12 mainclientStyleSection">
                                        <div className="card">
                                            <div className="card-header align-items-center d-flex">
                                                <h4 className="card-title mb-0 flex-grow-1">
                                                    Our Clients
                                                </h4>
                                            </div>
                                            <div className="card-body">

                                                {list && list.map((item) => {
                                                    return (
                                                        <a href="javascript:void(0)" class="clientSection">
                                                            <div class="row">
                                                                <div class="col-md-1 clientProfileImg">
                                                                    <img style={{ border: '2px solid #e7e7e7' }}
                                                                        className="rounded-circle"
                                                                        src={item.LOGO ? `${process.env.REACT_APP_BASE_URL}/v3/client-logo/${item.LOGO}` : "/assets/images/companies/img-2.png"}

                                                                        alt="Header Avatar"
                                                                    />
                                                                </div>
                                                                <div class="col-md-5 clientSectionLeft">
                                                                    <h3>
                                                                        {item.COMPANY_NAME}

                                                                    </h3>

                                                                    <p><i class="ri-user-fill"></i> Contact Person Name : {item.CLIENT_NAME}</p>
                                                                    <p><i class="ri-user-settings-fill"></i> GST No :  {item.GST_NO ? item.GST_NO : 'Not Available'}</p>
                                                                    <p><i class="ri-mail-fill"></i> Email :  {item.EMAIL}

                                                                    </p>
                                                                    <p><i class="ri-phone-fill"></i> Mobile : {item.PHONE}</p>

                                                                </div>
                                                                <div class="col-md-5 clientSectionRight">
                                                                    <p><i class="ri-building-fill"></i> Agreement Start : {item.AGREEMENT_START_DATE ? moment(item.AGREEMENT_START_DATE).format('DD-MM-YYYY') : 'Not Available'}</p>
                                                                    <p><i class="ri-bar-chart-grouped-line"></i> Agreement End : {item.AGREEMENT_END_DATE ? moment(item.AGREEMENT_END_DATE).format('DD-MM-YYYY') : 'Not Available'}</p>

                                                                    <p><i class="ri-chat-new-line"></i> Create On : {moment(item.CREATED_ON).fromNow()}</p>
                                                                    <p><i class="ri-time-fill"></i> Last Modified : {moment(item.MODIFIED_ON).format('DD-MM-YYYY')}</p>
                                                                </div>
                                                                <div class="col-md-1 clientMenuBtnSection">
                                                                    <a href="javascript:void(0)"
                                                                        onClick={() => {
                                                                            setShowEditModal(item)
                                                                        }}>
                                                                        <i class="ri-edit-2-line"></i>
                                                                    </a>
                                                                    <a href="javascript:void(0)"
                                                                        onClick={() => {
                                                                            setClientDetails(item)
                                                                            setShowClientDetailDrawerModal(true)
                                                                        }}
                                                                    >
                                                                        <i class="ri-eye-fill"></i>
                                                                    </a>
                                                                    <a href={`tel:${item.PHONE}`}>
                                                                        <i class="ri-phone-fill"></i>
                                                                    </a>
                                                                    <a href={constant.companyUserComponent.clientProfile.url.replace(":id", item.CLIENT_ID)}>
                                                                        <i class="ri-home-gear-fill"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </a>

                                                    )
                                                })}
                                                {count === 0 && <div className="noresult" >
                                                    <div className="text-center">
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/msoeawqm.json"
                                                            trigger="loop"
                                                            colors="primary:#121331,secondary:#08a88a"
                                                            style={{ width: 75, height: 75 }}
                                                        />
                                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                                        <p className="text-muted mb-0">
                                                            We've searched more than 150+ leads We did not find any
                                                            leads for you search.
                                                        </p>
                                                    </div>
                                                </div>}
                                                {count > 0 && <div className="customPaginationStyle">
                                                    < Pagination
                                                        limit={20}
                                                        totalCount={count}
                                                        range={5}
                                                        onPageChange={(page) => {
                                                            props.setCurrentPage(page)
                                                        }}
                                                        currentPage={currentPage}
                                                    />
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

            </div>

        </React.Fragment >
    )
}

export default withRouter(List)