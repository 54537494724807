import React, { useEffect, useState } from 'react'
import { v1_application_status_options } from '../../../../actions/v1/master_data'
import { ToastContainer, toast } from 'react-toastify'
import { getCookie, validateFunctionalForm } from '../../../../utils'
import axios from 'axios'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
const UpdateHiringStatusModal = (props) => {
    const [state, setState] = useState({
        STATUS: { name: 'STATUS', value: '', options: [], isRequired: false },
        DOJ: { name: 'DOJ', value: '', isRequired: false },
        REVENUE_GENERATED: { name: 'REVENUE_GENERATED', value: '', isRequired: false },
        INTERVIEW_DATE: { name: 'INTERVIEW_DATE', value: '', isRequired: false },
        INTERVIEW_TIME: { name: 'INTERVIEW_TIME', value: '', isRequired: false },
        INTERVIEW_PORTAL: { name: 'INTERVIEW_PORTAL', value: '', isRequired: false },
        INTERVIEW_SUBJECT: { name: 'INTERVIEW_SUBJECT', value: '', isRequired: false },
        INTERVIEWER_EMAIL: { name: 'INTERVIEWER_EMAIL', value: '', isRequired: false },
        OFFER_RELEASE_DATE: { name: 'OFFER_RELEASE_DATE', value: '', isRequired: false },
        EMPLOYEE_ID: { name: 'EMPLOYEE_ID', value: '', isRequired: false },
    })
    const [isAuthorized, setIsAuthorized] = useState({ 'google_meet': false });

    useEffect(() => {
        // Check if the cookie exists
        const authCookie = document.cookie
            .split('; ')
            .find(row => row.startsWith('google_meet_auth_token='));

        if (authCookie) {
            setIsAuthorized({ google_meet: true });
        }
    }, []);
    useEffect(() => {
        if (state.STATUS.value === '10') {
            setState(prevState => ({
                ...prevState,
                ['DOJ']: {
                    ...prevState['DOJ'],
                    isRequired: true
                },
                ['OFFER_RELEASE_DATE']: {
                    ...prevState['OFFER_RELEASE_DATE'],
                    isRequired: true
                },
                ['REVENUE_GENERATED']: {
                    ...prevState['REVENUE_GENERATED'],
                    isRequired: false
                },

            }));
        }
        else if (state.STATUS.value === '11') {
            setState(prevState => ({
                ...prevState,
                ['DOJ']: {
                    ...prevState['DOJ'],
                    isRequired: true
                }, ['REVENUE_GENERATED']: {
                    ...prevState['REVENUE_GENERATED'],
                    isRequired: true
                },
                ['OFFER_RELEASE_DATE']: {
                    ...prevState['OFFER_RELEASE_DATE'],
                    isRequired: false
                },
            }));
        }
        else if (state.STATUS.value === '7' || state.STATUS.value === '8' || state.STATUS.value === '9') {
            setState(prevState => ({
                ...prevState,
                ['INTERVIEW_DATE']: {
                    ...prevState['INTERVIEW_DATE'],
                    isRequired: true
                }, ['INTERVIEW_TIME']: {
                    ...prevState['INTERVIEW_TIME'],
                    isRequired: true
                },
                ['INTERVIEW_PORTAL']: {
                    ...prevState['INTERVIEW_PORTAL'],
                    isRequired: true
                },
                ['INTERVIEW_SUBJECT']: {
                    ...prevState['INTERVIEW_SUBJECT'],
                    isRequired: true
                },
                ['INTERVIEWER_EMAIL']: {
                    ...prevState['INTERVIEWER_EMAIL'],
                    isRequired: true
                },
                ['DOJ']: {
                    ...prevState['DOJ'],
                    isRequired: false
                }, ['REVENUE_GENERATED']: {
                    ...prevState['REVENUE_GENERATED'],
                    isRequired: false
                },
                ['OFFER_RELEASE_DATE']: {
                    ...prevState['OFFER_RELEASE_DATE'],
                    isRequired: false
                },
            }));
        }
        else {
            setState(prevState => ({
                ...prevState,
                ['INTERVIEW_DATE']: {
                    ...prevState['INTERVIEW_DATE'],
                    isRequired: false
                },
                ['INTERVIEW_TIME']: {
                    ...prevState['INTERVIEW_TIME'],
                    isRequired: false
                },
                ['INTERVIEW_PORTAL']: {
                    ...prevState['INTERVIEW_PORTAL'],
                    isRequired: false
                },
                ['INTERVIEW_SUBJECT']: {
                    ...prevState['INTERVIEW_SUBJECT'],
                    isRequired: false
                },
                ['INTERVIEWER_EMAIL']: {
                    ...prevState['INTERVIEWER_EMAIL'],
                    isRequired: false
                },
                ['DOJ']: {
                    ...prevState['DOJ'],
                    isRequired: false
                },
                ['REVENUE_GENERATED']: {
                    ...prevState['REVENUE_GENERATED'],
                    isRequired: false
                },
                ['OFFER_RELEASE_DATE']: {
                    ...prevState['OFFER_RELEASE_DATE'],
                    isRequired: false
                },
            }));
        }
    }, [state.STATUS.value])

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    const onSave = () => {
        if (validateFunctionalForm(state, setState)) {
            const token = getCookie('google_meet_auth_token');
            props.onSave({
                STATUS_ID: state.STATUS.value,
                DOJ: state.DOJ.value,
                INTERVIEW_DATE: state.INTERVIEW_DATE.value,
                INTERVIEW_TIME: state.INTERVIEW_TIME.value,
                INTERVIEW_PORTAL: state.INTERVIEW_PORTAL.value,
                INTERVIEW_SUBJECT: state.INTERVIEW_SUBJECT.value,
                INTERVIEWER_EMAIL: state.INTERVIEWER_EMAIL.value,
                OFFER_RELEASE_DATE: state.OFFER_RELEASE_DATE.value,
                REVENUE_GENERATED: state.REVENUE_GENERATED.value,
                EMPLOYEE_ID: state.EMPLOYEE_ID.value,
                token: token
            })
        }
        else {
            toast.error('Please Choose Hiring Status')

        }
    }
    useEffect(() => {
        v1_application_status_options().then((res) => {
            if (res.status) {
                setOptions('STATUS', res.result)
            }
            else {

            }
        })
    }, [])

    const location = useLocation()
    const handleAuthorization = async () => {
        try {
            // Make the API request
            const response = await axios.get(`https://mail.jobzseed.com/auth?source=${window.location.origin}&&path=${location.pathname}`); // Adjust the endpoint as needed
            // Handle success (e.g., redirect)
            const redirectUrl = response.data.redirectUrl || '/default-success-url';
            window.location.href = redirectUrl

        } catch (error) {
            console.error("Authorization failed:", error);
            alert("Failed to authorize. Please try again.");
        }
    };
    return (
        <React.Fragment>
            <ToastContainer />
            <div className="row ">
                <div className="col-lg-12 mb-3">
                    <div>
                        <label className="form-label">
                            Hiring Status
                        </label>
                        <select
                            className={state.STATUS.error && !state.STATUS.value ? 'form-select is-invalid' : 'form-select'}
                            onChange={onChange}
                            value={state.STATUS.value}
                            name={state.STATUS.name}

                        >
                            <option value={''}>
                                Select Hiring Status
                            </option>
                            {state.STATUS.options && state.STATUS.options.map((item) => {
                                return (<option value={item.STATUS_ID}>
                                    {item.STATUS_NAME}
                                </option>)
                            })}

                        </select>

                    </div>
                </div>
                {(state.STATUS.value === '10' || state.STATUS.value === '11') &&
                    <React.Fragment>
                        <div className="col-lg-6 mb-3">
                            <div>
                                <label className="form-label">
                                    {state.STATUS.value === '10' ? 'Date Of Joining' : 'Date of Joined'}
                                </label>
                                <input
                                    className={state.DOJ.error && !state.DOJ.value ? 'form-control is-invalid' : 'form-control'}
                                    onChange={onChange}
                                    value={state.DOJ.value}
                                    name={state.DOJ.name}
                                    type='date'
                                />

                            </div>
                        </div>
                        {state.STATUS.value === '10' && <div className="col-lg-6 mb-3">
                            <div>
                                <label className="form-label">
                                    Offer Released Date
                                </label>
                                <input
                                    className={state.OFFER_RELEASE_DATE.error && !state.OFFER_RELEASE_DATE.value ? 'form-control is-invalid' : 'form-control'}
                                    onChange={onChange}
                                    value={state.OFFER_RELEASE_DATE.value}
                                    name={state.OFFER_RELEASE_DATE.name}
                                    type='date'
                                />
                            </div>
                        </div>}

                        {state.STATUS.value === '11' &&
                            <React.Fragment>


                                <div className="col-lg-6 mb-3">
                                    <div>
                                        <label className="form-label">
                                            Revenue Generated
                                        </label>
                                        <input
                                            className={state.REVENUE_GENERATED.error && !state.REVENUE_GENERATED.value ? 'form-control is-invalid' : 'form-control'}
                                            onChange={onChange}
                                            value={state.REVENUE_GENERATED.value}
                                            name={state.REVENUE_GENERATED.name}
                                            type='number'
                                            placeholder='Revenue Generated'
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <div>
                                        <label className="form-label">
                                            Employee Id
                                        </label>
                                        <input
                                            className={state.EMPLOYEE_ID.error && !state.EMPLOYEE_ID.value ? 'form-control is-invalid' : 'form-control'}
                                            onChange={onChange}
                                            value={state.EMPLOYEE_ID.value}
                                            name={state.EMPLOYEE_ID.name}
                                            type='text'
                                            placeholder='Employee Id'
                                        />
                                    </div>
                                </div>
                            </React.Fragment>

                        }
                    </React.Fragment>

                }

                {(state.STATUS.value === '7' || state.STATUS.value === '8' || state.STATUS.value === '9') &&
                    <React.Fragment>
                        <div className="col-lg-6 mb-3">
                            <div>
                                <label className="form-label">
                                    Interview Portal <span className='text-danger'>*</span>
                                </label>
                                <select
                                    className={state.INTERVIEW_PORTAL.error && !state.INTERVIEW_PORTAL.value ? 'form-select is-invalid' : 'form-select'}
                                    onChange={onChange}
                                    value={state.INTERVIEW_PORTAL.value}
                                    name={state.INTERVIEW_PORTAL.name}
                                >
                                    <option value="" disabled>
                                        Select Interview Platform
                                    </option>
                                    <option value="google_meet">
                                        Google Meet
                                    </option>
                                    {/* <option value="microsoft_teams">
                                   Microsoft Teams
                                    </option> */}
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                            <div>
                                <label className="form-label">
                                    Title<span className='text-danger'>*</span>
                                </label>
                                <input
                                    type='text'
                                    placeholder='Interview Subject'
                                    className={state.INTERVIEW_SUBJECT.error && !state.INTERVIEW_SUBJECT.value ? 'form-control is-invalid' : 'form-control'}
                                    onChange={onChange}
                                    value={state.INTERVIEW_SUBJECT.value}
                                    name={state.INTERVIEW_SUBJECT.name}
                                />

                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div>
                                <label className="form-label">
                                    Interviewer Email<span className='text-danger'>*</span>
                                </label>
                                <input
                                    type='text'
                                    placeholder='Interview  (comma separated for multiple mail)'
                                    className={state.INTERVIEWER_EMAIL.error && !state.INTERVIEWER_EMAIL.value ? 'form-control is-invalid' : 'form-control'}
                                    onChange={onChange}
                                    value={state.INTERVIEWER_EMAIL.value}
                                    name={state.INTERVIEWER_EMAIL.name}
                                />

                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div>
                                <label className="form-label">
                                    Interview Date<span className='text-danger'>*</span>
                                </label>
                                <input
                                    type='date'
                                    className={state.INTERVIEW_DATE.error && !state.INTERVIEW_DATE.value ? 'form-control is-invalid' : 'form-control'}
                                    onChange={onChange}
                                    value={state.INTERVIEW_DATE.value}
                                    name={state.INTERVIEW_DATE.name}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                            <div>
                                <label className="form-label">
                                    Interview Time<span className='text-danger'>*</span>
                                </label>
                                <input
                                    className={state.INTERVIEW_TIME.error && !state.INTERVIEW_TIME.value ? 'form-control is-invalid' : 'form-control'}
                                    onChange={onChange}
                                    value={state.INTERVIEW_TIME.value}
                                    name={state.INTERVIEW_TIME.name}
                                    type='time'
                                />
                            </div>
                        </div>



                    </React.Fragment>

                }
                <div className="col-sm-12-auto ms-auto">
                    <div className="hstack gap-2">
                        <button type="button" className="btn btn-light-transperent" onClick={() => { props.onClose() }}>
                            Cancel
                        </button>
                        {state.INTERVIEW_PORTAL.value === 'google_meet' && !isAuthorized.google_meet ? <button
                            type="button" className="btn btn-light-transperent"
                            onClick={() => {
                                handleAuthorization()
                            }}
                        >
                            Authorise Now
                        </button> :
                            <button type="button" className="btn btn-light-solid" onClick={() => { onSave() }}>
                                Save
                            </button>}
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default UpdateHiringStatusModal