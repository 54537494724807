import React, { useEffect, useState } from 'react'
import { v3_ai_job_preferred_profiles, v3_job_applications, v3_job_apply } from '../../../actions/v3/jobApplication'
import JobOverview from '../../../components/companyUser/jobs/jobApplications/jobOverview'
import AppliedCandidates from '../../../components/companyUser/jobs/jobApplications/List/index'
import AIEvaluatedProfiles from '../../../components/companyUser/jobs/jobApplications/List/AIEvaluatedProfiles'
import Loader from '../../../components/common/Loader/circleLoader'
import PreviewModal from '../../../components/common/previewModal'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import constant from '../../../constant'
import { toast, ToastContainer } from 'react-toastify'

const List = (props) => {
  const [appliedCandidates, setAppliedCandidates] = useState(null);
  const [totalAppliedCandidates, setTotalAppliedCandidates] = useState(null)
  const [suggestedCandidates, setSuggestedCandidates] = useState(null);
  const [totalSuggestedCandidates, setTotalSuggestedCandidates] = useState(null)
  const [showJobOverview, setShowJobOverview] = useState(false);
  const [jobDetail, setJobDetail] = useState(null)
  const [jobQuestion, setJobQuestion] = useState(null)
  const [applicationStatus, setApplicationStatus] = useState('all')
  const [leadOwnerOptions, setLeadOwnerOptions] = useState(null)
  const [limit, setLimit] = useState(10)
  const [showLoader, setShowLoader] = useState(false)
  const [showAIEvaluatedCandidates, setShowAIEvaluatedCandidates] = useState(false);


  const [searchState, setSearchState] = useState({
    STATUS_ID: '',
    LEAD_OWNER: '',
    FROM_DATE: '',
    TO_DATE: ''

  })
  const onApplyJob = (CANDIDATE_ID) => {
    setShowLoader(true)
    v3_job_apply({
      JOB_ID: props.match.params.job_id,
      CANDIDATE_ID: CANDIDATE_ID,
      EMAIL: null,
      PHONE: null,
    }).then((res) => {
      if (res.status) {
        v3_ai_job_preferred_profiles(props.match.params.job_id, props.match.params.page_number, searchState.LEAD_OWNER, searchState.FROM_DATE, searchState.TO_DATE, limit).then((res) => {
          setShowLoader(false)
          if (res.status) {
            setSuggestedCandidates(res.result.suggestedCandidates);
            setTotalSuggestedCandidates(res.result.totalSuggestedCandidates.TOTAL)
          }
        })
        toast.success('Successfully Applied');
      }
      else {
        setShowLoader(false)
        toast.error(res.error);
      }
    })
  }

  useEffect(() => {
    setShowLoader(true)
    v3_job_applications(props.match.params.job_id, props.match.params.page_number, applicationStatus, searchState.STATUS_ID, searchState.LEAD_OWNER, searchState.FROM_DATE, searchState.TO_DATE, limit).then((res) => {
      setShowLoader(false)
      if (res.status) {
        setLeadOwnerOptions(res.result.leadOwnerOptions)
        setJobQuestion(res.result.job_question)
        setAppliedCandidates(res.result.appliedCandidates);
        setTotalAppliedCandidates(res.result.totalAppliedCandidates.TOTAL)
        setJobDetail({
          CLIENT_NAME: res.result.job_detail?.CLIENT_NAME,
          CLIENT_DETAILS: res.result.job_detail.CLIENT_DETAILS,
          CLIENT_ADDRESS: res.result.job_detail.CLIENT_ADDRESS,
          COMPANY_NAME: res.result.job_detail.COMPANY_NAME,
          JOB_TITLE: res.result.job_detail.JOB_TITLE,
          EMPLOYMENT_TYPE: res.result.job_detail.EMPLOYMENT_TYPE_ID,
          WORK_EXP_MIN: res.result.job_detail.WORK_EXP_MIN,
          WORK_EXP_MAX: res.result.job_detail.WORK_EXP_MAX,
          SALARY_CURRENCY: res.result.job_detail.SALARY_CURRENCY,
          SALARY_MIN: res.result.job_detail.SALARY_MIN,
          SALARY_MAX: res.result.job_detail.SALARY_MAX,
          JOB_DETAILS: res.result.job_detail.JOB_DETAILS,
          JOB_QUESTIONS: res.result.job_question,
          INCLUDE_WALK_IN_DETAILS: res.result.job_detail.IS_INCLUDE_WALK_IN,
          WALK_IN_START_DATE: res.result.job_detail.WALK_IN_START_DATE,
          WALK_IN_START_TIME: res.result.job_detail.WALK_IN_START_TIME,
          WALK_IN_END_TIME: res.result.job_detail.WALK_IN_END_TIME,
          WALK_IN_CONTACT_PERSON: res.result.job_detail.WALK_IN_CONTACT_PERSON,
          WALK_IN_CONTACT_NUMBER: res.result.job_detail.WALK_IN_CONTACT_NUMBER,
          WALK_IN_VENUE: res.result.job_detail.WALK_IN_VENUE,
          GOOGLE_MAP_URL: res.result.job_detail.GOOGLE_MAP_URL,
          WALK_IN_DURATION: res.result.job_detail.WALK_IN_DURATION,
          IS_WORK_FROM_HOME_JOB: res.result.job_detail.IS_WORK_FROM_HOME,
          WORK_FROM_TYPE: res.result.job_detail.TYPE_OF_WORK_FROM_HOME,
          OFFICE_LOCATION: res.result.job_detail.OFFICE_LOCATION,
          SKILLS: res.result.job_detail.KEYWORDS,
          INDUSTRY: res.result.job_detail.INDUSTRY_ID,
          FUNCTIONAL_AREA: res.result.job_detail.FUNCTIONAL_AREA_ID,
          ROLE: res.result.job_detail.ROLE_ID,
          NO_OF_VACANCY: res.result.job_detail.NO_OF_VACANCIES,
          QUALIFICATION: res.result.qualificationDetails,
          EDUCATION_QUALIFICATION: res.result.educationQualificationDetails,
          COURSE_SPECIALIZATION: res.result.specializationDetails,
          DATE_OF_EXPIRY: res.result.job_detail.DATE_OF_EXPIRY
        });

      }
    })
  }, [applicationStatus, props.match.params.page_number, searchState, limit])


  useEffect(() => {
    setShowLoader(true)
    v3_ai_job_preferred_profiles(props.match.params.job_id, props.match.params.page_number, searchState.LEAD_OWNER, searchState.FROM_DATE, searchState.TO_DATE, limit).then((res) => {
      setShowLoader(false)
      if (res.status) {
        setSuggestedCandidates(res.result.suggestedCandidates);
        setTotalSuggestedCandidates(res.result.totalSuggestedCandidates.TOTAL)
      }
    })
  }, [props.match.params.page_number, searchState, limit])

  const onSearch = (model) => {
    setSearchState(model)
    props.history.push(constant.companyUserComponent.jobApplications.url.replace(':job_id', props.match.params.job_id).replace(':page_number', 1))

  }


  return (
    <React.Fragment>
      <ToastContainer />
      {showLoader &&
        <Loader />
      }
      <PreviewModal isOpen={showJobOverview}
        onClose={() => { setShowJobOverview(false) }}
      >

        {jobDetail && <JobOverview
          jobData={jobDetail}
        />}
      </PreviewModal>

      {jobDetail && (showAIEvaluatedCandidates ? <AIEvaluatedProfiles
        onApplyJob={onApplyJob}
        showAIEvaluatedCandidates={showAIEvaluatedCandidates}
        setShowAIEvaluatedCandidates={setShowAIEvaluatedCandidates}
        totalSuggestedCandidates={totalSuggestedCandidates}
        suggestedCandidates={suggestedCandidates}
        jobData={jobDetail}
        setShowJobOverview={setShowJobOverview}
        jobQuestion={jobQuestion}
        onSearch={onSearch}
        leadOwnerOptions={leadOwnerOptions}
        limit={limit}
        setLimit={setLimit}
      /> : < AppliedCandidates
        showAIEvaluatedCandidates={showAIEvaluatedCandidates}
        setShowAIEvaluatedCandidates={setShowAIEvaluatedCandidates}
        totalAppliedCandidates={totalAppliedCandidates}
        setApplicationStatus={setApplicationStatus}
        appliedCandidates={appliedCandidates}
        jobData={jobDetail}
        setShowJobOverview={setShowJobOverview}
        jobQuestion={jobQuestion}
        onSearch={onSearch}
        leadOwnerOptions={leadOwnerOptions}
        limit={limit}
        job_id={props.match.params.job_id}
        setLimit={setLimit}
      />)}

    </React.Fragment>
  )
}

export default withRouter(List)