import React, { useState } from 'react'
import StepOne from '../../../../components/companyUser/jobs/postJob/step_one'
import StepTwo from '../../../../components/companyUser/jobs/postJob/step_two'
import { v3_add_step_two_job, v3_update_step_three_job } from '../../../../actions/v3/jobs'
import constant from '../../../../constant'
import styles from './postjob.module.css'
import BreadCrumb from '../../../../components/common/BreadCrumb'
import BarLoader from '../../../../components/common/Loader/barLoader'
const PostJob = (props) => {
    const [currentStep, setCurrentStep] = useState(1)
    const [showLoader, setShowLoader] = useState(false)
    const [StepOneFormData, setStepOneFormData] = useState(null)
    const [StepTwoFormData, setStepTwoFormData] = useState(null)

    const onSubmitStepTwo = (stepTwoData) => {
        setShowLoader(true)
        const model = {
            ...stepTwoData, ...StepOneFormData
        }
        v3_add_step_two_job(model).then((res) => {
            setShowLoader(false)

            if (res.status) {
                props.history.push(constant.companyUserComponent.updateJob.url.replace(':step', 3,).replace(':job_id', res.result))
            }
        })
    }



    return (
        <React.Fragment>
            {showLoader && <BarLoader />}

            <div className="container-fluid">
                <BreadCrumb
                    page={'Post A New Job'}
                    parentSection={<ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                            <a href="javascript: void(0);">Job Management</a>
                        </li>
                        <li className="breadcrumb-item active">Post Job</li>
                    </ol>}
                />

                <div className="row">
                    <div className="col-xl-12">
                        <div className={` ${styles.cardPostJobBox} card `}>
                            <div className="card-body">
                                <div className="form-steps" >
                                    <div className="pt-3 pb-4 mb-1">
                                        {/* <h5>Job Posting Form</h5> */}
                                        <h4 className={styles.innerTitlebox}>Post New Job</h4>
                                    </div>
                                    <div id="custom-progress-bar" className="progress-nav mb-4">
                                        <div className="progress" style={{ height: 1 }}>
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{ width: "0%" }}
                                                aria-valuenow={0}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            />
                                        </div>
                                        <ul
                                            className="nav nav-pills progress-bar-tab custom-nav"
                                            role="tablist"
                                        >
                                            <li className={`${styles.navItemli} nav-item`} >

                                                <button
                                                    className={currentStep === 1 ? "nav-link  active" : "nav-link "}
                                                >
                                                    1
                                                </button> <span className={styles.navText}>Client Details</span>
                                            </li>
                                            <li className={`${styles.navItemli} nav-item`}>
                                                <span className={styles.navText}></span>
                                                <button
                                                    className={currentStep === 2 ? "nav-link rounded-pill active" : "nav-link rounded-pill"}

                                                >
                                                    2
                                                </button>  <span className={styles.navText}>Job Details</span>
                                            </li>
                                            <li className={`${styles.navItemli} nav-item`} >
                                                <span className={styles.navText}></span>
                                                <button
                                                    className="nav-link rounded-pill"

                                                >
                                                    3
                                                </button> <span className={styles.navText}>Other Details</span>
                                            </li>

                                            <li className={`${styles.navItemli} nav-item`} >
                                                <span className={styles.navText}></span>
                                                <button
                                                    className="nav-link rounded-pill"
                                                    type="button"
                                                >
                                                    4
                                                </button> <span className={styles.navText}>Preview</span>
                                            </li>


                                        </ul>
                                    </div>
                                    {currentStep === 1 && <StepOne
                                        onSave={
                                            (model) => {
                                                setStepOneFormData(model);
                                                setCurrentStep(2);
                                            }
                                        }
                                        formData={StepOneFormData}
                                    />}
                                    {currentStep === 2 && <StepTwo
                                        setShowLoader={setShowLoader}
                                        onPrevious={() => { setCurrentStep(1) }}
                                        onSave={
                                            (model) => {
                                                setStepTwoFormData(model);
                                                onSubmitStepTwo(model)
                                            }
                                        }
                                        formData={StepTwoFormData}
                                    />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default PostJob