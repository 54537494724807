import React, { useEffect, useState } from 'react'
import { v3_job_applications_history } from '../../../../../actions/v3/jobApplication'
import styles from './styles.module.css'
import moment from 'moment';

const Detail = ({ label, value, description }) => {
    return (
        <div className={`${styles.detail} ${styles.detailBox}`}>
            <span className={styles.labeleHead}>{label}:</span> <span className={styles.valueLable}>
                <p>
                    {value}
                </p>

                {description && <p>Comment : {description}</p>}
            </span>

        </div>
    );
};
const Header = ({ label }) => {
    return (
        <div className={`${styles.detail} ${styles.detailBox}`}>
            <span className={styles.labeleHead}>{label}</span>
        </div>
    );
};

const History = ({ application_id }) => {
    const [history, setHistory] = useState([])
    useEffect(() => {
        debugger
        v3_job_applications_history(btoa(JSON.stringify({ application_id }))).then((res) => {
            if (res.status) {
                setHistory(res.result)
            }

        })
    }, [])
    return (
        <React.Fragment>
            <div className={styles.profilViewModalSection}>
                <div className="popupCandidateFormStyle">
                    <div className={styles.modalCandidateBox}>
                        <div className={styles.whatppBox}>
                            <Header className={styles.labelNotify} label={'Application Status History'} />
                        </div>
                    </div>
                    <div className={`${styles.detailscontainer} ${styles.detailscontainerBox}`}>
                        {history.map(item => {
                            return (
                                <Detail
                                    label={item.STATUS_NAME}
                                    value={`Updated by ${item.CREATED_BY_NAME} at ${moment(item.CREATED_ON).format('DD-MMM-YYYY')}`}
                                    description={item.DESCRIPTION}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default History