import React, { useEffect, useState } from 'react'
import SearchCandidate from '../../../components/companyUser/candidates/CallDirectory'
import { v3_lead_owners, v3_candidates_calling_directory } from '../../../actions/v3/candidates'
import moment from 'moment';

const List = () => {

    const [list, setList] = useState(null);
    const [count, setCount] = useState(null);
    const [todayCount, setTodayCount] = useState(null);
    const [limit, setLimit] = useState(10);

    const [currentPage, setCurrentPage] = useState(1);
    const [refresh, setRefresh] = useState(false);


    const [searchQuery, setSearchQuery] = useState({
        CANDIDATE_NAME: '',
        EMAIL: '',
        PHONE: '',
        SKILLS: '',
        FROM_DATE: '',
        TO_DATE: ''
    });


    useEffect(() => {

        v3_candidates_calling_directory(btoa(JSON.stringify({ PAGE: currentPage, ...searchQuery, LIMIT: limit }))).then((res) => {
            if (res.status) {
                setList(res.result.list)
                setCount(res.result.count)
                setTodayCount(res.result.todayCount)
            }
        })
    }, [currentPage, searchQuery, refresh, limit])



    return (
        <React.Fragment>

            <SearchCandidate
                setSearchQuery={setSearchQuery}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                list={list}
                count={count}
                todayCount={todayCount}
                setRefresh={setRefresh}
                refresh={refresh}
                limit={limit}
                setLimit={setLimit}
            />
        </React.Fragment>
    )
}

export default List