import React, { useEffect, useState } from 'react'
import { v3_search_candidates } from '../../../../actions/v3/candidates';
import Pagination from '../../../common/Pagination';
import moment from 'moment';

import DrawerModal from '../../../common/htmlDrawerModal';
import CanididateDetailDrawerModal from '../../candidates/canididateDetailModal';
import { v3_job_apply } from '../../../../actions/v3/jobApplication';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Modal from '../../../common/htmlModal';
import JobQuestionsAnswerModal from './jobQuestionsAnswerModal';

const CandidateMapModel = (props) => {
console.log(props)

    const [showCandidateDetailDrawerModal, setShowCandidateDetailDrawerModal] = useState(false);
    const [candidateDetails, setCandidateDetails] = useState(null);
    const [currentApplicationId, setCurrentApplicationId] = useState(null);
    const [showJobQuestionForm, setShowJobQuestionForm] = useState(false);
    




    const [state, setState] = useState({
        CANDIDATE_ID: { name: 'CANDIDATE_ID', value: '', error: '', isRequired: false },
        EMAIL: { name: 'EMAIL', value: '', error: '', isRequired: false },
        PHONE: { name: 'PHONE', value: '', error: '', isRequired: false },
    });

    const [list, setList] = useState(null);
    const [count, setCount] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }
    const searchCandidate = () => {
        if (state.CANDIDATE_ID.value || state.EMAIL.value || state.PHONE.value) {
            v3_search_candidates(btoa(JSON.stringify({ CANDIDATE_REFERENCE_ID: state.CANDIDATE_ID.value, CANDIDATE_NAME: '', EMAIL: state.EMAIL.value, PHONE: state.PHONE.value, SKILLS: '', PAGE: currentPage }))).then((res) => {
                if (res.status) {
                    setList(res.result.list)
                    setCount(res.result.count)
                }
            })
        }
    }
    useEffect(() => {
        searchCandidate()
    }, currentPage)

    const onApplyJob = (item) => {
        debugger
        v3_job_apply({
            JOB_ID: props.match.params.job_id,
            CANDIDATE_ID: item.CANDIDATE_ID,
            EMAIL: null,
            PHONE: null,
        }).then((res) => {
            if (res.status) {
                toast('Successfully Applied');
                setCurrentApplicationId(res.result)
                if (props.jobData.JOB_QUESTIONS.length > 0) {
                    setShowJobQuestionForm(true)
                }
                else {
                    console.log('no job questions')
                }
                // props.openJobQuestionForm()
                // props.onClose()

            }
            else {
                Swal.fire('Alert !', res.error, 'warning');
                // props.on Close()
            }
        })
    }

    return (
        <React.Fragment>
            <DrawerModal
                isOpen={showCandidateDetailDrawerModal}
                onClose={() => { setShowCandidateDetailDrawerModal(false) }}
            >
                {candidateDetails && <CanididateDetailDrawerModal
                    candidateDetails={candidateDetails}
                />}
            </DrawerModal>

           
            <div className="row">
            <Modal
                isOpen={showJobQuestionForm}
                header='Job Questions'
                onClose={()=>{setShowJobQuestionForm(false)}}
            >
                <JobQuestionsAnswerModal
                    onClose={() => { setShowJobQuestionForm(false) }}
                    QUESTIONS={props.jobData.JOB_QUESTIONS}
                    currentApplicationId={currentApplicationId}
                />

            </Modal>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header border-0">
                            <div className="row g-4 align-items-center">
                                <div className="col-lg-6 mb-3">
                                    <div>
                                        <label htmlFor="companyname-field" className="form-label">
                                            Candidate Reference ID


                                        </label>
                                        <input
                                            type="text"
                                            className={state.CANDIDATE_ID.error.length > 0 && !state.CANDIDATE_ID.value ? "form-control is-invalid" : "form-control"}
                                            placeholder="Candidate Reference ID"
                                            onChange={onChange}
                                            name={state.CANDIDATE_ID.name}
                                            value={state.CANDIDATE_ID.value}
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <div>
                                        <label htmlFor="companyname-field" className="form-label">
                                            Email

                                        </label>
                                        <input
                                            type="text"
                                            className={state.EMAIL.error.length > 0 && !state.EMAIL.value ? "form-control is-invalid" : "form-control"}
                                            placeholder="Email"
                                            onChange={onChange}
                                            name={state.EMAIL.name}
                                            value={state.EMAIL.value}
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <div>
                                        <label htmlFor="companyname-field" className="form-label">
                                            Phone

                                        </label>
                                        <input
                                            type="number"
                                            min={0}
                                            className={state.PHONE.error.length > 0 && !state.PHONE.value ? "form-control is-invalid" : "form-control"}
                                            placeholder="Phone"
                                            onChange={onChange}
                                            name={state.PHONE.name}
                                            value={state.PHONE.value}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <label htmlFor="companyname-field" className="form-label">
                                        &nbsp;
                                        {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}
                                    </label>
                                    <br />
                                    <button type="submit" onClick={() => {
                                        searchCandidate()
                                    }} className="btn btn-success" >
                                        Find
                                    </button>
                                    {/* <button type="button" class="btn btn-success" id="edit-btn">Update</button> */}
                                    {/* </div> */}

                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div>
                                <div className="table-responsive table-card">
                                    {list && <table className="table align-middle" id="customerTable">
                                        <thead className="table-light">
                                            <tr>

                                                <th className="sort">Candidate ID</th>
                                                <th className="sort">Name</th>
                                                <th className="sort">Email</th>
                                                <th className="sort">Phone</th>
                                                <th className="sort">Lead Owner</th>
                                                <th className="sort">Lead Add Date</th>
                                                <th className="sort">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {list.map((item) => {
                                                return (
                                                    <tr>

                                                        <td className="id">
                                                            <a
                                                                href="javascript:void(0);"
                                                                className="fw-medium link-primary"
                                                            >
                                                                RJZSHCL{item.CANDIDATE_ID}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1 name">{item.FULL_NAME}</div>
                                                            </div>
                                                        </td>
                                                        <td className="company_name">
                                                            {item.EMAIL}
                                                        </td>
                                                        <td className="phone">{item.PHONE}</td>
                                                        <td className="phone">{item.CREATED_BY_USER_NAME}</td>

                                                        <td className="date">{moment(item.CREATED_ON).format('DD MMM YYYY')}</td>
                                                        <td>
                                                            <ul className="list-inline hstack gap-2 mb-0">
                                                                <li
                                                                    className="list-inline-item edit"
                                                                    x data-bs-original-title="Call"
                                                                >
                                                                    <a
                                                                        href={`tel:${item.PHONE}`}
                                                                        className="text-muted d-inline-block"
                                                                    >
                                                                        <i className="ri-phone-line fs-16" />
                                                                    </a>
                                                                </li>

                                                                <li
                                                                    className="list-inline-item"
                                                                    onClick={() => {
                                                                        setCandidateDetails(item)
                                                                        setShowCandidateDetailDrawerModal(true)
                                                                    }}
                                                                >
                                                                    <a href="javascript:void(0);">
                                                                        <i className="ri-eye-fill align-bottom text-muted" />
                                                                    </a>
                                                                </li>

                                                                <li
                                                                    className="list-inline-item"
                                                                    onClick={() => {
                                                                        onApplyJob(item)

                                                                    }}
                                                                >
                                                                    <a href="javascript:void(0);">
                                                                        Apply Now
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                )
                                            })

                                            }
                                        </tbody>
                                    </table>}
                                    {count === 0 && <div className="noresult" >
                                        <div className="text-center">
                                            <lord-icon
                                                src="https://cdn.lordicon.com/msoeawqm.json"
                                                trigger="loop"
                                                colors="primary:#121331,secondary:#08a88a"
                                                style={{ width: 75, height: 75 }}
                                            />
                                            <h5 className="mt-2">Sorry! No Result Found</h5>
                                            <p className="text-muted mb-0">
                                                We've searched more than 150+ leads We did not find any
                                                leads for you search.
                                            </p>
                                        </div>
                                    </div>}
                                </div>
                                {count > 0 && < Pagination
                                    limit={20}
                                    totalCount={count}
                                    range={5}
                                    onPageChange={(page) => {
                                        setCurrentPage(page)
                                    }}
                                    currentPage={currentPage}
                                />}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(CandidateMapModel)