import React, { useEffect, useState } from 'react'
import Modal from '../../../../common/htmlModal';
import AddCandidateModalForm from '../addCandidateModalForm';
import JobQuestionsAnswerModal from '../jobQuestionsAnswerModal';
import moment from 'moment';
import DrawerModal from '../../../../common/htmlDrawerModal';
import EmailMessageModal from '../../../../common/EmailMessageModal';
import { ToastContainer, toast } from 'react-toastify';
import BreaCrumb from '../../../../common/BreadCrumb'
import CandidateMapModel from '../candidateApplyModal';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import CanididateDetailDrawerModal from '../../../candidates/canididateDetailModal';
import { v3_send_job_applications_mail, v3_update_hiring_status } from '../../../../../actions/v3/jobApplication';
import Pagination from '../../../../common/Pagination';
import constant from '../../../../../constant';
import UpdateHiringStatusModal from '../updateHiringStatusModal';
import { v1_application_status_options } from '../../../../../actions/v1/master_data';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import styles from './styles.module.css'

const List = (props) => {
  const { jobData, suggestedCandidates, limit, setLimit } = props;
  const [showCandidateDetailDrawerModal, setShowCandidateDetailDrawerModal] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState(null);

  const [showEmailModal, setShowEmailModal] = useState(null);


  const [searchState, setSearchState] = useState({
    LEAD_OWNER: { name: 'LEAD_OWNER', value: '', options: props.leadOwnerOptions ? props.leadOwnerOptions : [], error: '', isRequired: true },
    HIRING_STATUS: { name: 'HIRING_STATUS', value: '', options: [], error: '', isRequired: true },
    FROM_DATE: { name: 'FROM_DATE', value: '', options: [], error: '', isRequired: true },
    TO_DATE: { name: 'TO_DATE', value: '', options: [], error: '', isRequired: true },

  });

  useEffect(() => {
    // v3_crm_user_option_based_on_role().then(res => {
    //   if (res.status) {
    //     setOptions('LEAD_OWNER', res.result);
    //   }
    // });

    v1_application_status_options().then((res) => {
      if (res.status) {
        setOptions('HIRING_STATUS', res.result)
      }
      else {

      }
    })
  }, []);

  const setOptions = (name, options) => {
    setSearchState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        options: options
      }
    }));
  }

  const onSendEmailMEssage = (model) => {
    model.APPLICATION_ID = showEmailModal.APPLICATION_ID
    v3_send_job_applications_mail(model).then((res) => {
      if (res.status) {
        toast.success('Message Send Successfully')
        setShowEmailModal(null)
      }
      else {
        toast.error(res.error)

      }
    })
  }



  const onChange = (e) => {
    const { name, value } = e.target;
    setSearchState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value
      }
    }));
  }

  const onDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(suggestedCandidates.map((item) => {
      const model = {
        'Name': item.FULL_NAME,
        'Email': item.EMAIL,
        'Mobile': item.PHONE,
        'Skills': item.SKILLS,
        'Current Company': item.CURRENT_COMPANY ? item.CURRENT_COMPANY : 'Not Available',
        'Current Designation': item.CURRENT_DESIGNATION ? item.CURRENT_DESIGNATION : 'Not Available',
        'Notice Period': item.NOTICE_PERIOD ? item.NOTICE_PERIOD : 'Not Available',
        'Last Modified': moment(item.CURRENT_STATUS_DETAILS.CREATED_ON).format('DD-MM-YYYY'),
        'Lead Owner': item.LEAD_OWNER_NAME ? item.LEAD_OWNER_NAME : 'Not Available',
        'Added By': item.CREATED_BY_NAME ? item.CREATED_BY_NAME + ' at ' + moment(item.CREATED_ON).fromNow() : 'Not Available'
      }
      return model
    }));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, 'ai_suggested_' + jobData.JOB_TITLE + '.xlsx');
  }
  return (
    <React.Fragment>
      <ToastContainer />
      <Modal
        isOpen={showCandidateDetailDrawerModal}
        onClose={() => { setShowCandidateDetailDrawerModal(false) }}
      >
        {candidateDetails && <CanididateDetailDrawerModal
          candidateDetails={candidateDetails}
        />}
      </Modal>

      <Modal
        isOpen={showEmailModal}
        onClose={() => { setShowEmailModal(null) }}
        header={'New Message'}
      >
        {
          showEmailModal && <EmailMessageModal
            onClose={() => { setShowEmailModal(null) }}
            TO={showEmailModal.EMAIL}
            onSendEmailMEssage={onSendEmailMEssage}
          />
        }
      </Modal>



      <div className="container-fluid">
        <BreaCrumb
          page={jobData.JOB_TITLE}
          parentSection={<ol className="breadcrumb m-0">
            <li className="breadcrumb-item">
              <a href="javascript: void(0);">Jobs Management</a>
            </li>
            <li className="breadcrumb-item active">Applied Candidates</li>
          </ol>}

        />


        <div className="row">

          <div className="col-md-3">
            <form onSubmit={(e) => {
              e.preventDefault();
              props.onSearch({
                STATUS_ID: searchState.HIRING_STATUS.value,
                LEAD_OWNER: searchState.LEAD_OWNER.value,
                FROM_DATE: searchState.FROM_DATE.value,
                TO_DATE: searchState.TO_DATE.value,
              })

            }}>
              <div className={styles.leftFilterSection}>
                <div className={styles.filterTitlebox}>
                  <h5><span className={styles.span1}>Filter By</span>
                  </h5>
                </div>
                <div className={styles.filterInnerbox}>
                  <div className="search-box">
                    <label>Lead Owner: <span className="text-danger">&nbsp;*</span></label>
                    <select
                      className={searchState.LEAD_OWNER.error && !searchState.LEAD_OWNER.value ? 'form-select is-invalid search' : 'form-select search'}
                      onChange={onChange}
                      value={searchState.LEAD_OWNER.value}
                      name={searchState.LEAD_OWNER.name}
                    >
                      <option value=''>
                        Select Lead Owner
                      </option>
                      {searchState.LEAD_OWNER.options && searchState.LEAD_OWNER.options.map((item) => {
                        return (
                          <option key={item.USER_ID} value={item.USER_ID}>
                            {item.FULL_NAME}
                          </option>
                        )
                      })}
                    </select>
                    <i className={`  ${styles.toppos} ri-user-line search-icon`} />
                  </div>
                  <div className="search-box">
                    <label>Hiring Status: <span className="text-danger">&nbsp;*</span></label>
                    <select
                      className={searchState.HIRING_STATUS.error && !searchState.HIRING_STATUS.value ? 'form-select is-invalid' : 'form-select'}
                      onChange={onChange}
                      value={searchState.HIRING_STATUS.value}
                      name={searchState.HIRING_STATUS.name}
                    >
                      <option value=''>
                        Select Hiring Status
                      </option>
                      {searchState.HIRING_STATUS.options && searchState.HIRING_STATUS.options.map((item) => {
                        return (<option value={item.STATUS_ID}>
                          {item.STATUS_NAME}
                        </option>)
                      })}
                    </select>
                  </div>
                  <div className="accordion" id="accordionExample8">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingEight">
                        <button className={` ${styles.accordionButton} accordion-button`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                          From Date
                        </button>
                      </h2>
                      <div id="collapseEight" className="accordion-collapse collapse show" aria-labelledby="headingEight" data-bs-parent="#accordionExample8">
                        <div className={`${styles.accordionBody} accordion-body`}>
                          <input
                            type="date"
                            id='fromDate'
                            className={searchState.FROM_DATE.error && !searchState.FROM_DATE.value ? 'form-control is-invalid' : 'form-control'}
                            onChange={onChange}
                            value={searchState.FROM_DATE.value}
                            name={searchState.FROM_DATE.name}
                          />

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion" id="accordionExample9">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingNine">
                        <button className={` ${styles.accordionButton} accordion-button`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                          To Date
                        </button>
                      </h2>
                      <div id="collapseNine" className="accordion-collapse collapse show" aria-labelledby="headingNine" data-bs-parent="#accordionExample9">
                        <div className={`${styles.accordionBody} accordion-body`}>
                          <input
                            type="date"
                            id="toDate"
                            className={searchState.TO_DATE.error && !searchState.TO_DATE.value ? 'form-control is-invalid' : 'form-control'}
                            onChange={onChange}
                            value={searchState.TO_DATE.value}
                            name={searchState.TO_DATE.name}
                          />

                        </div>
                      </div>
                    </div>
                  </div>


                  <div className={` ${styles.gap10} hstack  align-center`}>
                    <button
                      type="button"
                      onClick={() => {
                        props.onSearch({
                          STATUS_ID: '',
                          LEAD_OWNER: '',
                          FROM_DATE: '',
                          TO_DATE: '',
                        })
                      }}
                      className={`${styles.btncanclePrf} btn  add-btn`}
                    >
                      Reset
                    </button>
                    <button
                      type="submit"

                      className={` ${styles.btnSearchPrf} btn add-btn ms-1`}
                    >
                      Search
                    </button>

                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="col-md-9">
            <div className={`${styles.paginationSection}`}>
              <div className={` row`}>
                <div className="col-md-6">
                  <div className={styles.rightSection}>
                    <div className={styles.box1}>Show</div>
                    <div className={styles.selectBox}>
                      <select className="form-select"
                        value={limit}
                        onChange={(e) => {
                          setLimit(e.target.value)
                        }}

                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                      </select>

                    </div>
                    <div className={styles.box2}>
                      Records Per Page
                    </div>
                  </div>
                </div>

                <div className="col-md-6">

                  {props.totalSuggestedCandidates > 0 &&
                    <div className={` ${styles.customPaginationStyle} customPaginationStyle`}>
                      <Pagination
                        totalCount={props.totalSuggestedCandidates}
                        limit={20}
                        range={5}
                        currentPage={Number(props.match.params.page_number)}
                        onPageChange={(page) => { props.history.push(constant.companyUserComponent.jobApplications.url.replace(':job_id', props.match.params.job_id).replace(':page_number', page)) }}
                      />
                    </div>}
                </div>

              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className={`${styles.downloadeSection}`}>
                    <button
                      type="button"
                      className="btn btn-primary add-btn ms-1"
                      onClick={() => { props.setShowAIEvaluatedCandidates(false) }}

                    >
                      Applied Candidates
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary add-btn ms-1"
                      onClick={() => { props.setShowJobOverview(true) }}

                    >
                      View Job Overview
                    </button>

                    <button
                      type="button"
                      className="btn btn-primary add-btn ms-1"
                      onClick={() => onDownloadExcel()}
                    >

                      Download Excel <i className="mdi mdi-download align-bottom me-1" />
                    </button>
                  </div>
                </div>

              </div>

            </div>
            <div class="col-md-12">
              <div className={`${styles.clientSectionLeft} card`}>

                <div className={`${styles.cardbody} card-body`}>
                  {suggestedCandidates && suggestedCandidates.map(item => {
                    return (
                      <a className={`${styles.clientSectionCard} clientSection`}>
                        <div class="row">

                          <div class="col-md-6 ">
                            <div className={styles.leftSectionCard}>
                              <div className={styles.profileAbotContact}>
                                <h3 className={styles.proName}> {item.FULL_NAME} </h3>
                                <p className={styles.contactdiv}> <i class="ri-user-fill"></i> Application Id : RJZSHJA{item.APPLICATION_ID}</p>
                                <p className={styles.contactdiv}><i class="ri-phone-fill"></i> {item.PHONE}</p>
                                <p className={styles.contactdiv}><i class="mdi mdi-email-outline"></i>  {item.EMAIL}</p>
                                <button className={`${styles.ViewProfiebtn}   ms-2"`}
                                  onClick={() => {
                                    setCandidateDetails(item);
                                    setShowCandidateDetailDrawerModal(true);
                                  }}
                                > View Full Profile </button>
                              </div>
                              <div className={styles.divinfobox}>
                                <span className={styles.hdinfo}>Current Designation :  </span><span>{item.CURRENT_DESIGNATION ? item.CURRENT_DESIGNATION : 'Not Available'}</span>
                              </div>
                              <div className={styles.divinfobox}>
                                <span className={styles.hdinfo}>Current Company :</span><span>{item.CURRENT_COMPANY ? item.CURRENT_COMPANY : 'Not Available'}</span>
                              </div>
                              <div className={styles.divinfobox}>
                                <span className={styles.hdinfo}> Skills :</span><span>{item.SKILLS || 'Not Available'}</span>
                              </div>
                              <div className={styles.divinfobox}>
                                <span className={styles.hdinfo}>Preferred Role :</span><span>{item.PREFERRED_ROLE_NAME ? item.PREFERRED_ROLE_NAME : 'Not Available'}</span>
                              </div>




                            </div>
                          </div>
                          <div class="col-md-6 clientSectionRight">

                            <div className={`${styles.infoLocationBox} `}>
                              {item.WORKING_LOCATION && <h6 className={styles.h6box}> <i class="ri-map-pin-fill" />&nbsp;{item.WORKING_LOCATION}&nbsp;&nbsp;</h6>}
                              {item.TOTAL_EXP_YEARS && <h6 className={styles.h6box}> <i class="ri-suitcase-fill" />&nbsp;{item.TOTAL_EXP_YEARS}&nbsp;&nbsp;</h6>}
                              {item.CURRENT_CTC && <h6 className={styles.h6box}> <i className={`bx bx-rupee ${styles.rupeeIcon}`} />&nbsp;{item.CURRENT_CTC}&nbsp;&nbsp;</h6>}

                            </div>

                            <div className={styles.rightSectionInfobx}>
                              <div className={styles.divinfobox}>
                                <span className={styles.hdinfo}>{" "}
                                  Notice Period :</span> <span>{item.NOTICE_PERIOD ? item.NOTICE_PERIOD : 'Not Available'}</span>
                              </div>
                              <div className={styles.divinfobox}>
                                <span className={styles.hdinfo}>{" "}Hiring Status :</span>
                                <span className={styles.hiringStatusWrapper}>
                                  {item.HIRING_STATUS_NAME ? item.HIRING_STATUS_NAME : 'Not Available'}
                                  {item?.INTERVIEW_DETAIL?.INTERVIEW_LINK && (
                                    <a
                                      target='_blank'
                                      href={item.INTERVIEW_DETAIL.INTERVIEW_LINK}
                                      className={`${styles.btninterviewPrf} btn add-btn ms-2`}
                                    >
                                      {item.INTERVIEW_DETAIL.INTERVIEW_PORTAL === 'google_meet' && (
                                        <img src="/images/google_meet.png" alt="Google Meet" className={styles.googleMeetIcon} />
                                      )}
                                      {" "}Join Interview
                                    </a>

                                  )}
                                </span>
                              </div>

                              <div className={styles.divinfobox}>
                                <span className={styles.hdinfo}>{" "}
                                  Lead Owner :</span> <span>{item.LEAD_OWNER_NAME ? item.LEAD_OWNER_NAME : 'Not Available'}</span>
                              </div>
                              <div className={styles.divinfobox}>
                                <span className={styles.hdinfo}> Add Via :</span><span>{item.SOURCE_TYPE ? item.SOURCE_TYPE : 'Not Available'}</span>
                              </div>
                              <div className={styles.divinfobox}>
                                <span className={styles.hdinfo}>{" "}
                                  Added By :</span> <span>{item.CREATED_BY_NAME ? item.CREATED_BY_NAME + ' at ' + moment(item.CREATED_ON).fromNow() : 'Not Available'}</span>
                              </div>
                              <div className={styles.divinfobox}>
                                <span className={styles.hdinfo}> Last Modified :</span> <span>{moment(item.MODIFIED_ON).format('DD-MM-YYYY')}</span>

                              </div>
                            </div>
                            <div className={styles.clientMenuBtnSection}>
                              <a href="javascript:void(0);"
                                onClick={() => { setShowEmailModal(item) }}
                              >
                                <i class="ri-question-answer-line"></i>
                              </a>
                              <a
                                onClick={() => {
                                  setCandidateDetails(item);
                                  setShowCandidateDetailDrawerModal(true);
                                }}
                              >
                                <i class="ri-eye-fill"></i>
                              </a>
                              <a href={`tel:${item.PHONE}`}>
                                <i class="ri-phone-fill"></i>
                              </a>

                              {/* <a href={
                                constant.companyUserComponent.candidateProfilePreviewAndApplyJob.url.replace(':id', item.CANDIDATE_ID).replace(':user_name', item.FULL_NAME)
                              }>
                                <i class="ri-home-gear-fill"></i>
                              </a> */}
                              <a href="javascript:void(0)"
                                onClick={() => {
                                  props.onApplyJob(item.CANDIDATE_ID)

                                }}>
                                <i class="ri-edit-box-line"></i>
                              </a>
                            </div>

                          </div>

                        </div>
                      </a>
                    )
                  })
                  }

                  {props.totalSuggestedCandidates === 0 && <div
                    className={`${styles.clientSectionCard} clientSection noresult`}
                  >
                    <div className="text-center">
                      <lord-icon
                        src="https://cdn.lordicon.com/msoeawqm.json"
                        trigger="loop"
                        colors="primary:#121331,secondary:#08a88a"
                        style={{ width: 75, height: 75 }}
                      />
                      <h5 className="mt-2">Sorry! No Result Found</h5>
                      <p className="text-muted mb-0">
                        We've searched more than 150+ leads We did not find any
                        leads for you search.
                      </p>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>

        </div>






      </div>




    </React.Fragment >
  )
}

export default withRouter(List)