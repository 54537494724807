import axios from "axios";

export const v4_resume_file_info = async (payload) => {
    const url = `https://ai.jobzseed.com/v1/resume/extract-info`;
    return axios.post(url, payload).then((res) => {
        return res.data;
    })

}

export const v4_resume_url_info = async (resumeUrl) => {
    const url = `https://ai.jobzseed.com/v1/resume/extract-info`;
    return axios.put(url, { resumeUrl: resumeUrl }).then((res) => {
        return res.data;
    })

}
