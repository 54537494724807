import React, { useEffect, useState } from 'react'
import { v3_dashboard_clients } from '../../../../actions/v3/dashboard'
import CountStats from '../../../../components/companyUser/administrator/dashboard/count_stats'
import Clients from '../../../../components/companyUser/administrator/dashboard/clients'
import Reminder from '../../../../components/companyUser/administrator/dashboard/Reminder'
import ApplicationStatistics from '../../../../components/companyUser/administrator/dashboard/applicationStatistics'
import { getGreeting, getStorage } from '../../../../utils'
import constant from '../../../../constant'
import BreadCrumb from '../../../../components/common/BreadCrumb'
import Overview from '../../../../components/companyUser/administrator/dashboard/overview'
import { v3_admin_count_stats } from '../../../../actions/v3/administrator'
const Index = () => {

  const [countStats, setCountStats] = useState(null);
  const [clientList, setClientList] = useState(null);
  const [ud, setUd] = useState(getStorage(constant.keys.companyUserDetails))

  useEffect(() => {
    v3_admin_count_stats().then((res) => {
      if (res.status) {
        setCountStats(res.result)
      }
    })

  }, [])





  useEffect(() => {

    v3_dashboard_clients().then((res) => {
      if (res.status) {
        setClientList(res.result.list)
      }
    })

  }, [])

  return (
    <React.Fragment>
      <div className="container-fluid">
        <BreadCrumb
          page='Admin Dashboard'
          parentSection={<ol className="breadcrumb m-0">
            <li className="breadcrumb-item">  
              <a href="javascript: void(0);">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Administrator</li>
          </ol>}
        />
        <div className="row">
          <div className="col-md-9">

            <CountStats
              countStats={countStats}
            />
            <div className='row'>
              <ApplicationStatistics />
              <Overview
                countStats={countStats}

              />
            </div>


          </div>

          <Reminder />

          {/*end col*/}
          <div className="col-xl-12 clientTableSection">
            <Clients
              clientList={clientList}
            />
          </div>
        </div>


      </div>


    </React.Fragment>
  )
}

export default Index