import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import queryString from 'query-string';

const Index = () => {
    const location = useLocation()
    let { token, path } = queryString.parse(location.search)

    useEffect(() => {
        if (token) {
            // Set the token in a cookie
            document.cookie = `google_meet_auth_token=${token}; path=/; max-age=${2 * 60 * 60}; secure; samesite=Lax`;
            console.log("Token set in cookie");
        }
    }, [token]);
    console.log(location)

    return (
        <div className="container mt-5">
            <div className="alert alert-success" role="alert">
                <h4 className="alert-heading">Authentication Successful!</h4>
                <p>Your authentication was successful. You can now proceed to schedule your interview.</p>
                <hr />
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        window.location.href = path
                    }}
                >
                    Click here to schedule interview
                </button>
            </div>
        </div>
    )
}

export default Index