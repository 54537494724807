import moment from 'moment'
import React, { useState } from 'react'
import Pagination from '../../common/Pagination'
import Modal from '../../common/htmlModal'
import nl2br from 'react-nl2br'
import BreadCrumb from '../../common/BreadCrumb'

const TemplatesList = (props) => {
    const { list, count, currentPage, setCurrentPage, onEditTemplate } = props
    const [showDetailModal, setShowDetailModal] = useState(null)
    return (
        <React.Fragment>
            <Modal
                header={'Template Details'}
                isOpen={showDetailModal} onClose={() => {
                    setShowDetailModal(null)
                }}
            >
                {showDetailModal && <div className="row">
                    <div className="col-lg-12 mb-3">
                        <div>
                            <label className="form-label fw-bold">
                                Template Name
                            </label>
                            <p>{showDetailModal.NAME}</p>
                        </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                        <div>
                            <label className="form-label fw-bold">
                                Template Category
                            </label>
                            <p>{showDetailModal.TYPE}</p>
                        </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                        <div>
                            <label className="form-label fw-bold">
                                Template Body
                            </label>
                            <p>{nl2br(showDetailModal.TEMPLATE_BODY)}</p>
                        </div>
                    </div>

                </div>
                }

            </Modal>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <BreadCrumb
                        page={''}
                        parentSection={ <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item">
                                <a href="javascript: void(0);">Candidate Management</a>
                            </li>
                            <li className="breadcrumb-item active">Message Templates</li>
                        </ol>}
                        />
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card cardPerformanceSection">
                                    <div className="card-header border-0">
                                        <div className="row g-4 align-items-center d-flex justify-content-center">
                                            <div className='col-sm'>
                                                <h2 className='main-title mb-0'>Message Templates</h2>
                                            </div>
                                            <div className="col-sm-auto">
                                                <div className="hstack gap align-center">
                                                    <button type="button" onClick={() => {
                                                        props.setShowAddTemplateModal(true)
                                                    }} className="btn btn-primary add-btn ms-1 download-table-xls-button">
                                                        <i className="ri-add-line align-bottom me-1" />
                                                        Add New Template
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div>
                                            <div className="table-responsive table-card mt-3 mb-1">
                                                <table
                                                    className="table table-performance-box align-middle table-nowrap"
                                                >
                                                    <thead className="table-light table-light-performance">
                                                        <tr>
                                                            <th className="sort">Template ID</th>
                                                            <th className="sort">Template Name</th>
                                                            <th className="sort">Template Category</th>
                                                            <th className="sort">Status</th>
                                                            <th className="sort">Last Modified</th>
                                                            <th className="sort">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {list && list.map((item) => {
                                                            return (
                                                                <tr>

                                                                    <td className="id">
                                                                        <a
                                                                            href="javascript:void(0);"
                                                                            className="fw-medium link-primary"
                                                                        >
                                                                            {item.TEMPLATE_ID}
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <div className="flex-grow-1 name">{item.NAME}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="company_name">
                                                                        {item.TYPE}
                                                                    </td>
                                                                    <td className="phone">
                                                                        {item.STATUS ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>}

                                                                    </td>
                                                                    <td className="phone">{moment(item.MODIFIED_ON).format('DD MMM YYYY')}</td>
                                                                    <td>
                                                                        <ul className="list-inline hstack gap-2 mb-0">
                                                                            <li
                                                                                className="list-inline-item"
                                                                                onClick={() => { setShowDetailModal(item) }}
                                                                            >
                                                                                <a href="javascript:void(0);">
                                                                                    <i className="ri-eye-fill align-bottom text-muted" />
                                                                                </a>
                                                                            </li>
                                                                            <li
                                                                                className="list-inline-item edit"
                                                                                onClick={() => { onEditTemplate(item) }}
                                                                            >
                                                                                <a
                                                                                    href="javascript:void(0);"
                                                                                    className="text-muted d-inline-block"
                                                                                >
                                                                                    <i className="ri-edit-line fs-16" />
                                                                                </a>
                                                                            </li>

                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })

                                                        }
                                                    </tbody>
                                                </table>
                                                {count == 0 && <div className="noresult" >
                                                    <div className="text-center">
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/msoeawqm.json"
                                                            trigger="loop"
                                                            colors="primary:#121331,secondary:#08a88a"
                                                            style={{ width: 75, height: 75 }}
                                                        />
                                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                                        <p className="text-muted mb-0">
                                                            We've searched more than 150+ leads We did not find any
                                                            leads for you search.
                                                        </p>
                                                    </div>
                                                </div>}
                                            </div>
                                            {count > 0 && < Pagination
                                                limit={20}
                                                totalCount={count}
                                                range={5}
                                                onPageChange={(page) => {
                                                    setCurrentPage(page)
                                                }}
                                                currentPage={currentPage}
                                            />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TemplatesList