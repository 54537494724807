import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Modal from '../../../common/htmlModal'
import ReminderModal from '../../reminder/reminderModal'
import Swal from 'sweetalert2'
import { v3_inactive_candidate_reminder } from '../../../../actions/v3/candidates'
import styles from './dashboard.module.css'
import Calendar from 'react-calendar'
import { v3_admin_dashbaord_today_candidate_reminder } from '../../../../actions/v3/administrator'
const Reminder = () => {
    const [list, setList] = useState(null)
    const [showReminderModal, setShowReminderModal] = useState(null)
    const [value, onChange] = useState(new Date());

    useEffect(() => {
        v3_admin_dashbaord_today_candidate_reminder().then((res) => {
            if (res.status) {
                setList(res.result.list)
            }
        })
    }, [])

    const onInactive = (id) => {
        Swal.fire({
            title: 'Are You Sure',
            text: 'You Want to inactive this reminder',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                v3_inactive_candidate_reminder(id).then((res) => {
                    if (res.status) {
                        v3_admin_dashbaord_today_candidate_reminder().then((res1) => {
                            if (res1.status) {
                                setList(res1.result.list)
                            }
                        })
                    }
                })
            }
        })

    }
    return (
        <React.Fragment>
            <Modal header='Reminder Details' isOpen={showReminderModal} onClose={() => setShowReminderModal(null)}>
                <ReminderModal
                    data={showReminderModal}
                />
            </Modal>
            <div className='col-md-3'>
                <div className={styles.calanderSection}>
                    <Calendar className={styles.calendarBoxSet} onChange={onChange} value={value} />
                </div>

                <div className={styles.remindersSectionBox}>
                    <h4>Today’s Reminders</h4>
                    <div className={styles.remindersListSectionBox}>
                        {list && list.length > 0 ? list.map((item) => {
                            return (
                                <div className={styles.remindersListBox}
                                    onClick={() => { setShowReminderModal(item) }}
                                >
                                    <div className={styles.dateDayBox}>
                                        <div className={styles.monthBox}>
                                            {moment(item.DATE).format('MMM')}
                                        </div>
                                        <div className={styles.dayDateBox}>
                                            <h5>{moment(item.DATE).format('DD')}<span>{moment(item.DATE).format('DDD')}</span></h5>

                                        </div>
                                    </div>
                                    <div className={styles.nameTimeBox}>
                                        <h3 title={item.CANDIDATE_NAME} > {item.CANDIDATE_NAME}</h3>
                                        <p><i className="bx bx-time "></i>{item.TIME}</p>
                                    </div>
                                    <div className={styles.activebtnBox}>
                                        {item.STATUS === 'A' ? <span className={styles.activeBtn}> Active</span> : <span className={styles.activeBtn}> Closed</span>}
                                        {item.STATUS === 'A' && <span className="text-muted fw-normal TodoEyeIcon">
                                            <i className='ri-delete-bin-2-fill' onClick={() => { onInactive(item.REMINDER_ID) }} />
                                        </span>}
                                    </div>

                                </div>
                            )
                        }) : <div className='noReminderVector'>
                            <img src="/assets/images/TodonoList.png" style={{ padding: 26, width: '100%' }} />
                            <h4 className='noremaindText'>"no reminder available"</h4>

                        </div>

                        }
                    </div>
                </div>

            </div>
        </React.Fragment>
        // <React.Fragment>
        //     <Modal header='Reminder Details' isOpen={showReminderModal} onClose={() => setShowReminderModal(null)}>
        //         <ReminderModal
        //             data={showReminderModal}
        //         />
        //     </Modal>

        //     <div className="card" style={{ height: '586px', }}>
        //         <div className="card-header">
        //             <div className="d-flex align-items-center">
        //                 <h6 className="card-title mb-0 flex-grow-1">Today's Candidate Reminder</h6>
        //                 <div className="flex-shrink-0">
        //                     <a href="apps-job-candidate-lists.html" className="viwMoreBtnLink">
        //                         View All <i className="ri-arrow-right-line" />
        //                     </a>
        //                 </div>
        //             </div>
        //         </div>
        //         <div className="row g-0">
        //             <div className="col-lg-12">
        //                 <div className="card-body border-end">

        //                     <div
        //                         data-simplebar="init"
        //                         style={{ maxHeight: 190 }}
        //                         className="px-3 mx-n3 simplebar-scrollable-x simplebar-scrollable-y"
        //                     >
        //                         <div
        //                             className="simplebar-wrapper"
        //                             style={{ margin: "0px -16px" }}
        //                         >
        //                             <div className="simplebar-height-auto-observer-wrapper">
        //                                 <div className="simplebar-height-auto-observer" />
        //                             </div>
        //                             <div className="simplebar-mask">
        //                                 <div
        //                                     className="simplebar-offset"
        //                                     style={{ right: 0, bottom: 0 }}
        //                                 >
        //                                     <div
        //                                         className="simplebar-content-wrapper"
        //                                         tabIndex={0}
        //                                         role="region"
        //                                         aria-label="scrollable content"
        //                                         style={{ height: "auto", overflow: "scroll" }}
        //                                     >
        //                                         <div
        //                                             className="simplebar-content"
        //                                             style={{ padding: "0px 16px" }}
        //                                         >
        //                                             <ul
        //                                                 className="list-unstyled mb-0 pt-0"
        //                                                 id="candidate-list"
        //                                             >
        //                                                 {list && list.length > 0 ? list.map((item) => {
        //                                                     return (
        //                                                         <>
        //                                                             <li>
        //                                                                 <a
        //                                                                     href="javascript:void(0);"
        //                                                                     className="d-flex align-items-center py-0"
        //                                                                 >
        //                                                                     <div className="flex-grow-1">
        //                                                                         <h5 className="fs-13 mb-3 text-truncate TodoMainDivStyle">
        //                                                                             <div class="row">
        //                                                                                 <div class="col-md-2">
        //                                                                                     <div className="TodoIcon">
        //                                                                                         <i class="ri-briefcase-fill"></i>
        //                                                                                     </div>
        //                                                                                 </div>
        //                                                                                 <div class="col-md-8">
        //                                                                                     <span className="candidate-name">
        //                                                                                         {item.CANDIDATE_NAME}
        //                                                                                     </span>{" "}
        //                                                                                     <span className="text-muted fw-normal DateTodo">
        //                                                                                         ({moment(item.DATE).format('DD MMM YYYY')} at {item.TIME})
        //                                                                                     </span>
        //                                                                                     <div className="candidate-position">
        //                                                                                         {item.STATUS === 'A' ? 'Active' : 'Closed'}
        //                                                                                     </div>
        //                                                                                 </div>
        //                                                                                 <div class="col-md-2">
        //                                                                                     {item.STATUS === 'A' && <span className="text-muted fw-normal TodoEyeIcon">
        //                                                                                         <i className='ri-delete-bin-2-fill' onClick={() => { onInactive(item.REMINDER_ID) }} />
        //                                                                                     </span>}
        //                                                                                     <span className="text-muted fw-normal TodoEyeIcon">
        //                                                                                         <i className='ri-eye-fill' onClick={() => { setShowReminderModal(item) }} />
        //                                                                                     </span>
        //                                                                                 </div>
        //                                                                             </div>
        //                                                                         </h5>
        //                                                                     </div>
        //                                                                 </a>
        //                                                             </li>
        //                                                         </>
        //                                                     )
        //                                                 }) : <div className='noReminderVector'>
        //                                                     <h4 className='noremaindText'>"no reminder available"</h4>
        //                                                     <img src="/assets/images/TodonoList.png" style={{ padding: 26, width: '100%' }} />
        //                                                 </div>
        //                                                 }
        //                                             </ul>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                             <div
        //                                 className="simplebar-placeholder"
        //                                 style={{ width: 195, height: 296 }}
        //                             />
        //                         </div>
        //                         <div
        //                             className="simplebar-track simplebar-horizontal"
        //                             style={{ visibility: "visible" }}
        //                         >
        //                             <div
        //                                 className="simplebar-scrollbar"
        //                                 style={{
        //                                     width: 168,
        //                                     transform: "translate3d(0px, 0px, 0px)",
        //                                     display: "block"
        //                                 }}
        //                             />
        //                         </div>
        //                         <div
        //                             className="simplebar-track simplebar-vertical"
        //                             style={{ visibility: "visible" }}
        //                         >
        //                             <div
        //                                 className="simplebar-scrollbar"
        //                                 style={{
        //                                     height: 121,
        //                                     transform: "translate3d(0px, 0px, 0px)",
        //                                     display: "block"
        //                                 }}
        //                             />
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>

        //         </div>

        //     </div>
        // </React.Fragment>
    )
}

export default Reminder